.blog {
  &__title{
    color: $brand-purple;
    font-weight: 600;
    letter-spacing: -0.025em;
  }
  &__container{
    margin-top: $space-m;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, minmax(0,1fr));
    @include media-breakpoint-down(sm){
      grid-template-columns: repeat(1, minmax(0,1fr));
    }
  }
  &__content{
    grid-column: span 2 / span 2;
    padding-bottom: 1rem;
    @include media-breakpoint-down(sm){
      grid-column: span 1 / span 1;
    }
  }
  &__sidebar{
    grid-column: span 1 / span 1;
  }
  &-content-top-label {
    position: absolute;
    top: 0;
    padding: 1px 7px;
    z-index: 1;
    background: $c-primary;
    color: $c-white;
    font-size: em($f-size-s);
    pointer-events: none;
    height: 23px;
  }

  &-innercontent {
    youtube{
      display: flex;
    }
    img{
      width: 100%;
      height: auto;
      max-width:611px;
      max-height:400px;
      aspect-ratio: attr(width) / attr(height);
      display: block;
    }
    h1,h2,h3{
      color: $brand-purple;
      letter-spacing: -0.025em;
    }
  }

  &__author{
    img {
      max-width: 100px;
      max-height: 100px;
      width: 100%;
      height: 100%;
    }
    &-content{
      display: flex;
      gap: 1rem;
    }
  }

  &-content-thumbnail img {
    max-width: 220px;
    max-height: 220px;
    width: 100%;
    height: 100%;
  }

  &__related {
    a{
      color: $brand-purple;
    }
    &-content{
      display: flex;
      gap: 2rem;
    }
  }

  &__related h3, &__author h3 {
    border-bottom: 1px solid $c-grey-lighter;
  }

  a &-title-h1, &-title-h1 {
    line-height: 100%;
    color: $brand-purple;
    letter-spacing: -0.025em;
    font-weight: 600;
    padding-bottom: $space-s;
    border-bottom: 1px solid $c-grey-lighter;
  }

  &-sidebar {

    font-size: em($f-size-base);
    color: $c-grey-plein;
    padding: 0 $space-m;

    .txt-right {
      text-align: right;
    }

    > div {
      padding: $space-m;
    }

    .newsletter {
      margin: 0 -15px;
      background-color: #DCF5F9;

      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      @include media-breakpoint-down(md) {
        margin-top: 56px;
        position: relative;
      }

      @include media-breakpoint-down(sm) {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        margin-left: $space-min-30;
        margin-right: $space-min-30;
      }

      @include media-breakpoint-up(lg) {
        h3 {
          line-height: 25px;
        }
      }

      .sign-up {
        width: 104px;
        height: 104px;
        background-color: $c-promo;
        color: #FFFFFF;
        border-radius: 50%;
        text-align: center;
        line-height: 26px;
        font-size: 25px;
        padding-top: 13px;
        @include media-breakpoint-up(lg) {
          float: right;
          margin-top:13px;
        }
        @include media-breakpoint-down(md) {
          margin: -70px auto 22px auto;
        }
      }

      ul.fa-ul {
        margin-left: $space-l;
        margin-bottom: $space-l;
        .fas.fa-check {
          color: $c-primary;
        }
      }
    }

    .bottom-sidebar {

      @include media-breakpoint-up(md) {

        border: 1px solid #e4e4e4;
        border-top:0px solid;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        @include media-breakpoint-up(md) {
          margin-left: $space-min-15;
          margin-right: $space-min-15;
        }
      }
      >.social-icons {
        margin-bottom: 0;
        padding-bottom: 0;
        @include media-breakpoint-up(sm) {
          margin-left: $space-min-30;
          margin-right: $space-min-30;
        }
        @include media-breakpoint-up(md) {
          margin-left: 0;
          margin-right: 0;
        }

        h3 {
          margin-bottom: 0;
          line-height: 28px;
        }

        a {
          display: inline-block;
          width: 39px;
          height: 39px;
          padding: 0;
          margin: 10px 10px 10px 0;
          text-align: center;

          &.facebook {
            color: #F5B9D0;
          }

          &.linkedin {
            color: #AEDDF1;
          }

          &.instagram {
            color: #80D4BC;
          }

          &.pinterest {
            color: #3FC8F2;
          }
        }
      }

      >.categories {
        padding-top: 0;
        margin-bottom: 0;
        @include media-breakpoint-up(sm) {
          margin-left: $space-min-30;
          margin-right: $space-min-30;
        }
        @include media-breakpoint-up(md) {
          margin-left: 0;
          margin-right: 0;
        }
        h3 {
          margin-bottom: 4px;
        }

        a {
          color: $c-link;
        }
      }

      >.search {

        @include media-breakpoint-down(sm) {
          margin-top: $space-m;
          margin-left: $space-min-30;
          margin-right: $space-min-30;
        }
        @include media-breakpoint-up(md) {
          margin-top: $space-m;
          margin-left: 0;
          margin-right: 0;
        }
        padding: 0;
        border: 1px solid $c-grey-lighter;
        border-radius: 3px;
        background-color: $c-white;

        input {
          background: none;
          border: none;
          padding: 6px 50px 6px 15px;
          width: 100%;
        }

        button {
          position: absolute;
          background: none;
          border: none;
          padding: 6px 15px;
          right: 0;
          top: 0;
          margin-right: 5px;

          i.fa-search {
            color: $c-grey;
          }
        }
      }
    }

    .btn-newsletter, .btn-search {
      margin: 0;
      background-color: $c-primary;
      color: $c-white;
      font-family: inherit;
      border: 0;
      text-decoration: none;

      &:hover {
        background-image: linear-gradient(transparent, rgba(0, 0, 0, .05) 40%, rgba(0, 0, 0, .1));
      }
    }

    .btn-newsletter {
      margin-top: $space-m;
      min-width: 123px;
    }

    .btn-search {
      width: 10%;
      min-width: 54px;
    }

    .form-newsletter, .form-search {
      padding: 2px 7px 5px 7px;
      border: 1px solid $c-grey-lightest;
      border-radius: 3px;

      @include placeholder {
        color: $c-grey-dark;
      }
    }

    .form-newsletter {
      min-width: 108px;
      width: 100%;
    }

    .form-search {
      min-width: 108px;
      width: 70%;
    }
  }
}
