//
// Base styles
//

.btn {
  display: inline-block;
  //font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: $input-btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, em($f-size-base), $line-height, $btn-border-radius);
  @include transition(all .2s ease-in-out);

  &,
  &.active,
  &:active {
    &.focus,
    &:focus {
      @include tab-focus();
    }
  }
  @include hover-focus {
    text-decoration: none;
  }

  &.focus {
    text-decoration: none;
  }

  &.active,
  &:active {
    background-image: none;
    outline: 0;
  }

  &.disabled,
  &:disabled {
    cursor: $cursor-disabled;
    opacity: 0.65;
    box-shadow: none;
  }

  &.btn-small {
    padding: 2px $space-s;
    font-size: em($f-size-s);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  &.btn-mini {
    padding: 0 $space-xs;
    font-size: em($f-size-xs);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  &.btn-nieuwsbrief {
    background-color: $brand-purple;
    color: $c-white;
    font-family: inherit;
    padding: 6px 12px;
    border: 0 rgba(0, 0, 0, 0);
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
    margin-left: $space-xs;

    &:hover {
      background-color: $brand-purple-hover;
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
  box-shadow: none;
}

.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
  box-shadow: none;
}

.btn-info {
  @include button-variant($btn-info-color, $c-primary, $c-primary);
  box-shadow: none;
}

.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
  box-shadow: none;
}

.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
  box-shadow: none;
}

.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
  box-shadow: none;
}

// Remove all backgrounds
.btn-primary-outline {
  @include button-outline-variant($btn-primary-bg);
}

.btn-secondary-outline {
  @include button-outline-variant($btn-secondary-border);
}

.btn-info-outline {
  @include button-outline-variant($btn-info-bg);
}

.btn-success-outline {
  @include button-outline-variant($btn-success-bg);
}

.btn-warning-outline {
  @include button-outline-variant($btn-warning-bg);
}

.btn-danger-outline {
  @include button-outline-variant($btn-danger-bg);
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: normal;
  color: $c-link;
  border-radius: 0;

  &,
  &.active,
  &:active,
  &:disabled {
    background-color: transparent;
    box-shadow: none;
  }

  &,
  &:active,
  &:focus {
    border-color: transparent;
  }
  @include hover {
    border-color: transparent;
  }
  @include hover-focus {
    color: $c-link-hover;
    text-decoration: underline;
    background-color: transparent;
  }

  &:disabled {
    @include hover-focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

//
// Button Sizes
//
.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, em($f-size-base), $line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, em($f-size-s), $line-height-sm, $btn-border-radius-sm);
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: $space-xs;
}

// Specificity overrides
input[type="button"],
input[type="reset"],
input[type="submit"] {
  &.btn-block {
    width: 100%;
  }
}

//
// Add to cart button
//
button {
  &.btn-addcart-s {
    &:before {
      top: -1px;
      position: relative;
    }
  }
}

.btn-addcart {
  font-size: em($f-size-base);
  line-height: 2 !important;
  //font-weight: 100;
  cursor: pointer;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  color: $c-white;
  border-radius: 3px;
  background-color: $c-cart-bg;

  &:focus,
  &:hover {
    background-color: $c-cart-hover;
    color: $c-white;
  }
  &.added-to-cart {
    opacity: 0.5;
    color: rgba(255, 255, 255, .5);
  }

  &-padding {
    padding: 0 $space-xs;
  }

  &.btn-block {
    i {
      padding-left: $space-xs;
    }
  }
}

.btn-addcart-s {
  width: 30px;
  height: 30px;
  line-height: 1.5 !important;

  &:before {
    top: 1px;
    position: relative;
  }
}

.btn-addcart-m {
  aspect-ratio: 1;
  line-height: 2.25 !important;
  height: 100%;
}

.btn-addcart-m,
.btn-addcart-s {
  padding: 0;

  &:focus,
  &:hover {
    &:before {
      content: "\f067";
    }
  }
}


.btn-icon-gallery {
  @include transition();
  font-size: 30px;
  font-weight: 100;
  cursor: pointer;
  text-align: center;
  color: $c-link;
  border: 1px solid $c-link;
  border-radius: 1px;
  background-color: $c-white;
  padding: 0;
  width: 75px;
  line-height: 100%;
  height: 55px;
  margin: 0 auto;

  &:hover {
    color: $c-link-hover;
    border: 1px solid $c-link-hover;
  }
}

.btn-banner {
  white-space: initial;
  font-size: em($f-size-xl);
  margin-bottom: 100px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  color: $c-grey-darker;
  padding-left: 30px;
  &:hover {
    background-color: $c-grey-lightest;
    color: $c-grey-darker;
    border-color: $c-primary
  }
}

.back-to-top {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  z-index: 900;
  text-align: center;
  top: auto;
  @include media-breakpoint-down(md) {
    background-color: rgba($c-primary, 0.5);
    left: 25px;
    bottom: 25px;
    right: auto;
  }
  @include media-breakpoint-up(sm) {
    background-color: $c-primary;
    left: auto;
    bottom: 25px;
    right: 25px;
  }
  position: fixed;
  right: $space-l;
  border-radius:4px;

  i {
    width: 40px;
    height: 40px;
    display: block;
    font-size: em(30px);
    color: $c-white;
    padding-top: 3px;
  }

  &.cd-is-visible {
    visibility: visible;
    opacity: 1;
  }

  &::before {
    opacity: 0;
    position:absolute;
    right:4px;
    color:$c-white;
    font-size: em(30px);
    font-family: "Font Awesome 5 Duotone";
    font-weight: 900;
    content: "\f0aa";
    transition: all .25s ease-in-out;
  }

  @include media-breakpoint-up(sm) {
    &:hover {
      box-shadow: $c-black;
      background-color: rgba($c-link-hover, .7);
      padding-top: 25px;
      padding-bottom: 25px;

      &::before {
        top: 0px;
        opacity: 1;
        transition: all .25s ease-in-out;
      }

      i {
        opacity: 0;
        padding-bottom: 25px;
        transition: all .25s ease-in-out;
      }
    }
  }

  .no-touch &:hover {
    background-color: rgba($c-primary, 1);
    opacity: .5;
  }
}

[data-toggle="buttons"] {
  .btn {
    @include button-outline-variant($btn-primary-bg);
    white-space: normal;

    &.active {
      @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    }
  }
}
