/**
 * @section : Global variables
 * @project : Plein brand
 */

/*------------------------------------*\
    COLORS
\*------------------------------------*/
$c-black:         #000000;
$c-white:         #ffffff;

$c-primary:       #00B2FF;
$c-primary-light: #DCF5F9;
$c-primary-light-new: #CCF0FF;
$c-link:          $c-primary;
$c-link-hover:    #00B8F1 !default;
$c-grey-plein:    #333B3F;
$c-grey-icon:     #C9C9CB;
$c-cart-bg:       #82C81E;
$c-cart-hover:    #50C81E;
$c-trustpilot:    #007F4E;
$success-color:   #43A047;
$c-yellow:        #FFE46E;
$c-promo:         #FF8CD2;
$c-promo-bg:      #4d5457;
$c-favorites:     #FF0000;

$c-grey-lightest: #F0F0F0;
$c-grey-lighter:  #F0F0F0;
$c-grey-light:    #DFDFDF;
$c-grey:          $c-grey-icon;
$c-grey-dark:     $c-grey-plein;
$c-grey-darker:   #333A40;
$c-grey-darkest:  #333333;

$brand-primary:   $c-link !default;
$brand-success:   $c-cart-bg !default;
$brand-info:      $c-link-hover !default;
$brand-warning:   #F0AD4E !default;
$brand-danger:    #D9534F !default;
$brand-purple:    #3a114e !default;
$brand-purple-hover: lighten($brand-purple, 10%);
$brand-yellow:    #FFE46E !default;

$petgamma-primary:    #6fac46;

  /*------------------------------------*\
      TYPOGRAPHY
  \*------------------------------------*/
$f-font-family: 'Source Sans Pro', sans-serif;
$line-height: 1.5 !default;

$f-size-xl:     30px;   // h1
$f-size-l:      25px;   // h2
$f-size-m:      20px;   // h3
$f-size-ms:     18px;   // default
$f-size-base:   15px;   // default
$f-size-s:      13px;
$f-size-xs:     10px;

/*------------------------------------*\
    BASIC SPACES (margin/padding etc)
\*------------------------------------*/
$space-min-15: -15px;
$space-min-30: -30px;
$space-min-40: -40px;
$space-min-55: -55px;

$space-xxs:   2px;
$space-xs:   5px;
$space-s:   10px;
$space-m:   15px;
$space-l:   25px;
$space-xl:  40px;
$space-xxl: 60px;

/*------------------------------------*\
    MEDIA QUERY BREAKPOINTS
\*------------------------------------*/
/* Breakpoints, like your shirt sizes */
$bp-sm: 544px;
$bp-md: 768px;
$bp-lg: 960px;
$bp-xl: 1170px;

/* Max widths of the various breakpoints */
$bp-max-xs: $bp-sm - 1;
$bp-max-sm: $bp-md - 1;
$bp-max-md: $bp-lg - 1;
$bp-max-lg: $bp-xl - 1;

/*------------------------------------*\
    PLEIN HEADER
\*------------------------------------*/
$plein-header-top: 90px;
$plein-navigation: 55px;
$header-placeholder: $plein-header-top + $plein-navigation;

/*------------------------------------*\
    BS4 GRID
\*------------------------------------*/
// Grid breakpoints
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: ( xs: 0, xsm:415px, sm: 544px, md: 768px, lg: 960px, xl: 1170px ) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: ( xl: 1170px ) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
// Set the number of columns and specify the width of the gutters.
$enable-grid-classes: true;
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer: 1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: ( 0: ( x: 0, y: 0 ), 1: ( x: $spacer-x, y: $spacer-y ), 2: ( x: ($spacer-x * 1.5), y: ($spacer-y * 1.5) ), 3: ( x: ($spacer-x * 3), y: ($spacer-y * 3) ) ) !default;
$border-width: 1px !default;

$hr-border-color: rgba(0,0,0,.1) !default;
$hr-border-width: $border-width !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: (4 / 3) !default;
$line-height-sm: 1.5 !default;

$border-radius: 3px !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

$border-radius-base:        4px !default;
$border-radius-large:       6px !default;
$border-radius-small:       3px !default;

$border-radius-banner: 8px !default;

$component-active-color: #fff !default;
$component-active-bg: $brand-primary !default;

$caret-width: 0.3em !default;
$caret-width-lg: $caret-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x: 1rem !default;
$btn-padding-y: 0.375rem !default;
$btn-font-weight: normal !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($c-black,.125) !default;

$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: $btn-primary-bg !default;

$btn-secondary-color: $c-grey-dark !default;
$btn-secondary-bg: #fff !default;
$btn-secondary-border: #ccc !default;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: $btn-info-bg !default;

$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: $btn-success-bg !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: $btn-warning-bg !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: $btn-danger-bg !default;

$btn-link-disabled-color: $c-grey-light !default;

$btn-padding-x-sm: 0.75rem !default;
$btn-padding-y-sm: 0.25rem !default;

$btn-padding-x-lg: 1.25rem !default;
$btn-padding-y-lg: 0.75rem !default;

//== Close
//
//##

$close-font-weight:           bold !default;
$close-color:                 #000 !default;
$close-text-shadow:           0 1px 0 #fff !default;


// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

// Forms

$input-padding-x: 0.75rem !default;
$input-padding-y: 0.375rem !default;

$input-bg: #fff !default;
$input-bg-disabled: $c-grey-lightest !default;

$input-color: $c-grey-darker !default;
$input-border-color: #ccc !default;
$input-btn-border-width: $border-width !default; // For form controls and buttons
$input-box-shadow: inset 0 1px 1px rgba(0,0,0,.075) !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-border-focus: #66afe9 !default;
$input-box-shadow-focus: rgba(102,175,233,.6) !default;

$input-color-placeholder: #999 !default;

$input-padding-x-sm: 0.75rem !default;
$input-padding-y-sm: 0.275rem !default;

$input-padding-x-lg: 1.25rem !default;
$input-padding-y-lg: 0.75rem !default;

$input-height: (21 + ($input-padding-y * 2)) !default;
$input-height-lg: (27 + ($input-padding-y-lg * 2)) !default;
$input-height-sm: (19 + ($input-padding-y-sm * 2)) !default;

$form-group-margin-bottom: $spacer-y !default;

$input-group-addon-bg: $c-grey-lightest !default;
$input-group-addon-border-color: $input-border-color !default;

$cursor-disabled: not-allowed !default;

// Form validation icons
$form-icon-success: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==" !default;
$form-icon-warning: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+" !default;
$form-icon-danger: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=" !default;

// Labels
$label-default-bg: $c-grey-light !default;
$label-primary-bg: $brand-primary !default;
$label-success-bg: $brand-success !default;
$label-info-bg: $brand-info !default;
$label-warning-bg: $brand-warning !default;
$label-danger-bg: $brand-danger !default;

$label-color: #fff !default;
$label-link-hover-color: #fff !default;
$label-font-weight: bold !default;

// List group

$list-group-bg: #fff !default;
$list-group-border-color: #ddd !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-hover-bg: #f5f5f5 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border: $list-group-active-bg !default;
$list-group-active-text-color: lighten($list-group-active-bg, 40%) !default;

$list-group-disabled-color: $c-grey-light !default;
$list-group-disabled-bg: $c-grey-lightest !default;
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color: #555 !default;
$list-group-link-hover-color: $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem !default;
$table-sm-cell-padding: 0.3rem !default;

$table-bg: transparent !default;
$table-bg-accent: rgba(0,0,0,.05) !default;
$table-bg-hover: rgba(0,0,0,.075) !default;
$table-bg-active: $table-bg-hover !default;

$table-border-width: $border-width !default;
$table-border-color: $c-grey-lightest !default;

// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken($state-success-bg, 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken($state-info-bg, 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$mark-bg:                        $state-warning-bg !default;
$state-warning-border:           darken($state-warning-bg, 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken($state-danger-bg, 5%) !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop:  990 !default;
$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-navbar-sticky:     1030 !default;
$zindex-modal-bg:          1040 !default;
$zindex-modal:             1050 !default;

// Tooltips

$tooltip-max-width:           200px !default;
$tooltip-color:               #fff !default;
$tooltip-bg:                  #000 !default;
$tooltip-opacity:             .9 !default;
$tooltip-padding-y:           3px !default;
$tooltip-padding-x:           8px !default;
$tooltip-margin:              3px !default;

$tooltip-arrow-width:         5px !default;
$tooltip-arrow-color:         $tooltip-bg !default;


// Popovers

$popover-inner-padding:               1px !default;
$popover-bg:                          #fff !default;
$popover-max-width:                   276px !default;
$popover-border-width:                $border-width !default;
$popover-border-color:                rgba(0,0,0,.2) !default;
$popover-box-shadow:                  0 5px 10px rgba(0,0,0,.2) !default;

$popover-title-bg:                    darken($popover-bg, 3%) !default;
$popover-title-padding-x:             14px !default;
$popover-title-padding-y:             8px !default;

$popover-content-padding-x:           14px !default;
$popover-content-padding-y:           9px !default;

$popover-arrow-width:                 10px !default;
$popover-arrow-color:                 $popover-bg !default;

$popover-arrow-outer-width:           ($popover-arrow-width + 1px) !default;
$popover-arrow-outer-color:           fade-in($popover-border-color, .05) !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding:         15px !default;

$modal-dialog-margin:         10px !default;
$modal-dialog-sm-up-margin-y: 30px !default;

$modal-title-padding:         15px !default;
$modal-title-line-height:     $line-height !default;

$modal-content-bg:               #fff !default;
$modal-content-border-color:     rgba(0,0,0,.2) !default;
$modal-content-border-width:     $border-width !default;
$modal-content-xs-box-shadow:    0 3px 9px rgba(0,0,0,.5) !default;
$modal-content-sm-up-box-shadow: 0 5px 15px rgba(0,0,0,.5) !default;

$modal-backdrop-bg:           #000 !default;
$modal-backdrop-opacity:      .5 !default;
$modal-header-border-color:   #e5e5e5 !default;
$modal-footer-border-color:   $modal-header-border-color !default;
$modal-header-border-width:   $modal-content-border-width !default;
$modal-footer-border-width:   $modal-header-border-width !default;

$modal-lg:                    900px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;

//== Panels
//
//##

$panel-bg:                    #fff !default;
$panel-body-padding:          15px !default;
$panel-heading-padding:       10px 15px !default;
$panel-footer-padding:        $panel-heading-padding !default;
$panel-border-radius:         $border-radius !default;

//** Border color for elements within panels
$panel-inner-border:          #ddd !default;
$panel-footer-bg:             #f5f5f5 !default;

$panel-default-text:          $c-grey-dark !default;
$panel-default-border:        #ddd !default;
$panel-default-heading-bg:    #f5f5f5 !default;

$panel-primary-text:          #fff !default;
$panel-primary-border:        $brand-primary !default;
$panel-primary-heading-bg:    $brand-primary !default;

$panel-success-text:          $state-success-text !default;
$panel-success-border:        $state-success-border !default;
$panel-success-heading-bg:    $state-success-bg !default;

$panel-info-text:             $state-info-text !default;
$panel-info-border:           $state-info-border !default;
$panel-info-heading-bg:       $state-info-bg !default;

$panel-warning-text:          $state-warning-text !default;
$panel-warning-border:        $state-warning-border !default;
$panel-warning-heading-bg:    $state-warning-bg !default;

$panel-danger-text:           $state-danger-text !default;
$panel-danger-border:         $state-danger-border !default;
$panel-danger-heading-bg:     $state-danger-bg !default;

// Badges

$badge-default-bg:            $c-grey-light !default;
$badge-primary-bg:            $brand-primary !default;
$badge-success-bg:            $brand-success !default;
$badge-info-bg:               $brand-info !default;
$badge-warning-bg:            $brand-warning !default;
$badge-danger-bg:             $brand-danger !default;

$badge-color:                 $c-white !default;
$badge-link-hover-color:      $c-white !default;
$badge-font-size:             75% !default;
$badge-font-weight:           200 !default;
$badge-padding-x:             .4em !default;
$badge-padding-y:             .25em !default;

$badge-pill-padding-x:        .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:    10rem !default;
