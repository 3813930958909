#snackbar {
 width: 100%;
 z-index: 999;
 visibility: hidden;
 opacity: 0;
 position: relative;
 margin: 0 auto;

 .snackbar-content {
   background-color: $c-grey-plein;
   color: $c-white;
   text-align: center;
   padding: $space-m;
 }
}
