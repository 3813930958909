.fade {
  opacity: 0;
  transition: opacity .15s linear;

  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;

  &.show {
    display: block;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: height;
}

/* Generate keyframes for header-btn animation */
@include keyframes(addToCart) {
  0% {
    @include transform(scale(1) rotate(0));
  }

  33% {
    @include transform(scale(1.5) rotate(-30deg));
  }

  66% {
    @include transform(scale(1.5) rotate(30deg));
  }

  100% {
    @include transform(scale(1) rotate(0));
  }
}