.horizontal-scroll {
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  &--remove-container-gap {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &--scroll-padding {
    padding-bottom: 10px;
  }

  &__item {
    display: inline-block;
    position: relative;
    &:first-child{
      padding-left: 15px;
    }
    &:last-child{
      padding-right: 15px;
    }
  }

  &__logo {
    max-width: 10rem;
  }
}
