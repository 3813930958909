.youtube-player {
  @include transition();
  position: relative;
  padding-bottom: 56.23%;
  height: 0;
  overflow: hidden;
  width: 100%;
  background: $c-black;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;
  }

  img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
    cursor: pointer;
  }

  .play-container {
    background: transparentize($c-black, 0.5);
    width: 60px;
    height: 60px;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    position: absolute;
    cursor: pointer;

    &:hover {
      .play {
        border-left-color: $c-white;
      }
    }
  }

  .play {
    @include transition();
    margin-left: 18px;
    margin-top: 10px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid $c-primary;
  }
}