.custom-content {
  .customer-order-list {
    .customer-order-list-item {
      &:hover {
        background: $c-grey-lightest;
        cursor: pointer;
      }
    }
  }
}
