.footer-information {
  background-color: $c-white;
  text-align: center;
  color: $c-grey-plein;
  padding: 2rem 0 0 0;

  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
  &__wrapper{
    margin: 1rem 0;
    @include media-breakpoint-up(md){
      display: flex;
      justify-content: space-between;
    }
  }

  &__info {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 1rem;
    }
  }

  &__logos {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__name {
    font-weight: bold;
    color: $c-primary;
  }
}
