.star-container {
  ul {
    padding: 0;
    display: inline-block;
    cursor: pointer;
    li {
      display: inline;
      font-size: $f-size-m;

      &:before {
        font-family: 'Font Awesome 5 Pro';
        content: "\f005";
        color: $c-yellow;
      }

      &.star-empty:before {
        font-weight: normal;
      }

      &.star-filled:before {
        font-weight: bold;
      }
    }
  }
}

.rating {
  color: $c-yellow;
  i {
    margin-right: 4px;
  }
}

.reviews {
  h1, h2 {
    padding-bottom: 20px;
    border-bottom: 1px solid $c-grey-lighter;
  }
  .buttons {
    .btn {
      font-size: 1rem;
      & + .btn {
        margin-right: 10px;
      }
    }
  }
  .review {
    border-bottom: 1px solid $c-grey-lighter;
    margin-bottom: 15px;
    padding-bottom: 15px;
    .review-shared {
      font-size: 0.857rem;
      line-height: 1rem;
      color: $c-grey;
    }
    .review-list {
      margin-top: 10px;
      .review-positive i { color: $c-primary; }
      .review-negative i { color: $c-yellow; }
      li {
        padding-left: 20px;
        i { margin-left: -20px; }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .reviews {
    .review {
      .review-info {
        color: $c-grey-plein;
        margin-left: 15px;
      }
      .review-name {
        font-size: 0.857rem;
      }
      .review-list {
        margin: 10px -5px 0;
        > div {
          padding: 0 5px;
          li {
            padding-left: 15px;
            i { margin-left: -15px; }
          }
        }
      }
    }
  }
}
