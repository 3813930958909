.no-page {
  margin: 40px 0;

  .images {
    width: 100%;
    margin: 0 auto;
  }

  .no-page-dog {
    width: 100%;
    margin: 15px 0;
  }

  .mobile-icons {
    margin: 15px 0;
    width: 120%;
    position: relative;
    left: -4%;

    a {
      width: 15%;
      display: inline-block;

    }
    img {
      width: 75%;
      margin: 0 auto;
    }
  }
}

@include media-breakpoint-up(sm) {
  .no-page {
    .images {
      width: 75%;
      margin-left: 0;

      .no-page-dog {
        width: 135px;
        display: inline-block
      }

      .mobile-icons {
        display: inline-block;
        position: relative;
        float: left;
        padding-top: 55px;
        width: 70%;
        left: -1%;

        img {
          width: 70%
        }
      }
    }
  }
}
