body#product-page {
  h2 {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-top: 15px;
  }

  .product-details h2 {
    margin-top: 0;
  }

  .border-text {
    border-bottom: 1px solid $c-grey-lighter;
    text-align: center;
    margin: 15px -15px;

    > div {
      margin-bottom: -10px;
      line-height: 20px;

      > span {
        background: white;
        padding: 0 $space-s;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  .owl-custom-theme {
    height: 300px;
    @include media-breakpoint-down(md){
      height: 225px;
    }
  }

  .owl-custom-thumbnails{
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    margin: 0.25rem 0;
    @include media-breakpoint-down(md){
      padding-bottom: 1rem;
    }
    button{
      background-color: transparent;
      border: 1px solid $c-grey-light;
      border-radius: 8px;
      transition: border 0.125s ease-in-out;
      padding: 0;
      &:focus{
        outline: none;
      }
      &:hover{
        border: 1px solid $c-link-hover;
      }
      &.active{
        border: 1px solid $c-primary;
      }
      img {
        padding: 0.5rem;
      }
    }
  }

  .promo-label, .csr-label {
    float: left;
    border-radius: 0 4px 4px 0;
    margin: 0 0 10px -15px;
  }

  .promo-selection-button {
      height: 41px;
      margin-top: -3px;
      margin-left: -6px;
      width: auto;
      min-width: 80px;
      max-width: 200px;
      border-radius: 4px;
      display: inline-block;
      color: $c-grey-plein;
      text-align: center;
      border: 1px solid $c-cart-bg;
      padding: 7px;
      span {
        color: $c-cart-bg;
      }
      &:hover > span {
        color: $c-white;
      }
      &:hover {
        background: $c-cart-hover;
        border: 1px solid $c-cart-hover;
        color: $c-white;
      }
  }

  .add-to-favorites, .add-to-favorites-ajax {
    margin-left: auto;
  }

  .product-recommended {
    font-size: clamp(1rem, 2vw, 1.25rem);
  }

  .product-error-text {
    margin-top: 20px;

    .muted {
      color: $c-grey;
    }

    form, a.btn-info {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .relations-buttons {
    padding: 0;
    margin: 5px -5px 5px 0;

    li {
      display: inline-block;
      margin: 0 10px 10px 0;

      a {
        color: $c-grey-plein;
        text-align: center;
        border-radius: 4px;
        border: 1px solid $c-grey-lighter;
        padding: 7px;
        display: block;

        &:hover {
          background: $c-grey-lightest;
        }
      }

      &.active a {
        border-color: $c-primary;
        cursor: default;

        &:hover {
          background: $c-white;
        }
      }
    }
  }

  .relations-dropdown {
    padding-left: 7px;
    margin-bottom: 15px;
    margin-top: 5px;
    max-width: 250px;
    text-align: left;
  }

  .relations-images {
    margin-top: 5px;
    .owl-custom-theme {
      height: 125px;
    }
    .image-container {
      max-width: 125px;
      max-height: 125px;
      margin: 0 auto;
      padding: 1px;
    }

    a {
      display: block;
      padding: 2px;

      img {
        width: 100%;
      }
    }

    .current {
      border: 1px solid $c-primary;
      cursor: default;
      border-radius: 4px;
    }
  }

  .relations-horizontal {
    ul {
      margin-bottom: 0;
    }

    .odd {
      padding-right: 5px;
    }

    .even {
      padding-left: 5px;
    }

    a {
      color: $c-grey-plein;

      img {
        width: 92px;
        margin-right: 10px;
      }

      .product-name {
        font-size: 0.857rem;
        line-height: 16px;
        height: 51px;
        overflow: hidden;
      }
    }

    .relation-bottom-container {
      display: flex;
      justify-content: space-between;
    }

    .relation-price {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      line-height: 20px;
      font-size: 18px;
    }

    .product-add-to-cart {
      margin: 0;
      padding: 0 10px;
    }
  }

  .relations-vertical {
    ul {
      margin: 0 -7px;
    }

    li {
      padding: 0 7px;
    }

    a {
      color: $c-grey-plein;

      img {
        width:120px;
        height:120px;
        margin:0 auto 15px;
        @include media-breakpoint-only(lg){
          width:109px;
          height:109px;
          margin:0 0 15px 0;
        }

      }

      .product-name {
        font-size: 0.857rem;
        line-height: 16px;
        height: 51px;
        overflow: hidden;
        margin-bottom: 10px;
      }
    }

    .relation-bottom-container {
      display: flex;
      justify-content: space-between;
    }

    .relation-price {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      line-height: 20px;
      font-size: 18px;
    }

    .product-add-to-cart {
      padding: 0 10px;
      margin: 0;
    }
  }
  .volume-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    &.variant {
      gap: 5px 15px;
    }
    li {
      a {
        max-height: 54px;
        color: $c-grey-plein;
        text-align: center;
        border-radius: 4px;
        border: 1px solid $c-grey-lighter;
        padding: 7px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        line-height:19px;
        &.variant{
          padding: 15px 30px;
        }

        &:hover {
          border-color: $c-grey-light;
          background: $c-grey-lightest;
        }
        &.product-discount {
          font-weight: bold;
        }
        .product-total{
          color: #808080;
        }
      }

      &.active a {
        border-color: $c-primary;
        cursor: default;

        &:hover {
          background: $c-white;
        }
      }
    }
  }

  .product-usps {
    margin-bottom: 0;
    li {
      line-height: 1.8rem;

      i {
        color: $brand-primary;
        margin-right: 15px;
      }
    }
  }

  figure {
    margin: 0;
  }

  .product-details-toggle {
    padding-left: 0;
    @include media-breakpoint-up(lg){
      display: block;
      width: 100%;
      position: relative;
      margin-top: -30px;
      &:focus{
        border: 0;
        outline: none;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -25px;
        left: 0;
        height: 50px;
        width: 100%;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        border-bottom: 1px solid #F0F0F0;
      }
      span {
        display: inline-block;
        padding: 5px 14px;
        border: 1px solid $c-primary;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        background: white;
      }
      i {
        transform: rotate(90deg);
      }
    }
  }

  .product-details {
    display: none;
  }

  .add-to-favorites, .add-to-favorites-ajax {
    color: #FDB4D0;
  }
}
.product-reviews{
  &__header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
}
.product-sponsored {
  color: gray;
  font-size: 0.8rem;
}

@include media-breakpoint-down(md) {
  body#product-page {
    h2, h3 {
      font-size: 1.2rem;
    }

    .product-add-to-cart {
      font-size: 1.6rem;
      padding: 15px 20px;
      margin-top: 10px;
      float: right;
    }

    .promo-selection-button {
      height: 20px;
      padding: 22px 15px;
      margin-top: 80px;
      float: right;
      width: 200px;
    }

    #product-select-quantity {
      width: 120px;
    }

    .relations-images {
      .a {
        padding: 2px;

        img:before {
          font-size: 1px;
        }
      }
    }

    .relations-horizontal {
      li {
        &.odd, &.even {
          padding: 0 15px;
        }

        .relation-cell {
          width: 50%;

          & + .relation-cell {
            vertical-align: bottom;
          }
        }

        img {
          width: 133px;
        }

        .product-name {
          margin-bottom: 10px;
        }

        .product-add-to-cart {
          font-size: 1rem;
          height: 41px;
          width: 41px;
        }
      }
    }

    .relations-vertical {
      .carousel-item {
        padding: 0 5px;
        img {
          width:120px;
          height:120px;
          margin:0 auto 15px;
        }
        img:before {
          font-size: 1px;
        }
      }

      .product-add-to-cart {
        font-size: 1rem;
        height: 41px;
        width: 41px;
      }
    }

    .relations-horizontal h3,

    .relations-vertical h3 {
      padding: 0 15px 10px;
      margin: 0 -15px 15px;
      border-bottom: 1px solid $c-grey-lighter;
    }

    .product-reviews {
      h2 {
        margin: 0 -15px 15px;
        padding: 0 15px 15px;
        line-height: 2.3rem;
      }

      .review {
        margin: 0 -15px 15px;
        padding: 0 15px 15px;
        div.collapse,
        div.collapsing {
          padding-left: 15px;
        }
      }
    }
    .product-label {
      display: inline-block;
      margin-left: -15px;
      border-radius: 0 4px 4px 0;
      position: absolute;
      z-index: 2;
    }

    .add-to-favorites, .add-to-favorites-ajax {
      position: absolute;
      z-index: 2;
      right: 15px;
      font-size: 1.9rem;
      line-height: 1.2rem;
    }
  }
}

@include media-breakpoint-down(xsm) {
  body#product-page {
    .relations-horizontal {
      li + li {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  body#product-page {
    .border-text {
      margin: 15px 0;
    }

    .product-label {
      display: block;
      border-radius: 4px 0 0 4px;
      margin: 10px -15px 0 0;
    }

    #product-select-quantity {
      width: auto;
      min-width:60px;
      border-radius: 4px;
      display: inline-block;
      padding-right: 30px;
    }

    .product-add-to-cart {
      height: 41px;
      margin-right: 5px;
    }

    .add-to-favorites, .add-to-favorites-ajax {
      border: 1px solid $c-grey-lighter;
      padding: 7px 12px;
      border-radius: 4px;

      &:hover {
        background: $c-grey-lightest;
      }
    }
  }
}
