@import "../critical/product-preview-grid";

.brand-label {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $brand-purple;
}

.brand-usp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1;
  position: absolute;
  top: -10px;
  right: 0;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  font-weight: bold;
  text-align: center;
  background: $c-promo;
  color: white;
  z-index: 1;

  @include media-breakpoint-down(md) {
    top: 0;
  }

  &.upTo {
    line-height: 12px;

    span {
      font-size: 10px;
    }
  }
}

.leaf-usp {
  display: block;
  position: absolute;
  top: -10px;
  right: 0;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-weight: bold;
  text-align: center;
  color: white;
  z-index: 1;

  @include media-breakpoint-down(md) {
    top: 0;
    right: 20px;
  }
}

#promotion-page {
  #promotions, #campaigns, #promotion-banners, #brands, #season, #choices, #texts, #price-filter, #age-filter {
    border-bottom: 1px solid $c-grey-lightest;
  }

  .categories-wrapper{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
  }

  .intro-text-highlight {
    color: $c-primary;
  }

  .title {
    padding-left: 3px;
    margin-bottom: 15px;
  }

  .show-all {
    margin-top: 30px;
    color: $c-primary;
  }

  .page-section {
    padding: 30px 0;
  }

  .product-section {
    padding: 30px 0 0 0;
  }

  .product-section + .product-section {
    padding: 15px 0 0 0
  }

  .banner-usp {
    padding-top: 30px;
    font-size: 20px;
    color: $c-promo;
  }

  #top-banner {
    &.page-section {
      padding: 0;

      img {
        width: 100%;
      }
    }

    p {
      margin: 0;
      padding: 30px 0;
    }
  }

  #campaigns, #promotion-banners {
    .campaign-slide {
      display: inline-block;
    }

    .background-slide-yellow {
      background-color: $c-yellow;
      border-radius: 8px;
      display: flex;
      align-items: end;
    }

    .background-slide-yellow, .background-slide {
      height: 140px;
      width: 241px;
      margin: 0 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 240px;
        max-height: 140px;
        height: auto;
      }
    }

    img {
      width: auto;
      margin: 0 auto;
    }
  }

  #promotion-banners {
    padding: 0 0 30px 0;

    .campaign-slide {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }

    img {
      width: 100%;
      margin: 0 auto;
      border-radius: 0;
    }
  }

  #brands {
    .brands-container {
      .brand > a, .gift > a {
        position: relative;
        display: inline-block;
        width: 100%;
      }

      img {
        margin: 0 auto;
        max-width: 100px;
      }

      @include media-breakpoint-down(md) {
        .brand > a, .gift > a {
          img {
            max-width: 200px;
          }
        }
      }

      @include media-breakpoint-up(md) {
        .brand {
          display: inline-block;
          padding-top: 30px;
          width: 16%;

          &:hover {
            img {
              @include transform(scale(1.1));
              @include transition();
            }
          }
        }
        .gift {
          display: inline-block;
          padding-top: 30px;
          width: 19%;

          &:hover {
            img {
              @include transform(scale(1.1));
              @include transition();
            }
          }
        }
      }
    }
  }

  #categories {
    padding-bottom: 15px;

    .title {
      margin-bottom: 15px;
    }

    .img-container {
      padding: 15px;

      img {
        width: 100%;
        border-radius: $border-radius-banner;
      }
    }
    .category-image{
      border-radius: $border-radius-banner;
    }
  }

  @include media-breakpoint-down(md) {
    #season,
    #brands,
    #campaigns,
    #promotions,
    #promotion-banners,
    #choices,
    #texts,
    #categories {
      .desktop {
        display: none !important;
      }

      .mobile {
        display: block !important;
      }
    }
  }

  @include media-breakpoint-up(md) {
    #season,
    #promotion-banners,
    #brands,
    #campaigns,
    #promotions,
    #choices,
    #texts,
    #categories {
      .desktop {
        display: block !important;
      }

      .mobile {
        display: none !important;
      }
    }
  }

  #promotions {
    .mobile {
      margin: 0;

      h4 {
        color: #707070;
        margin: 0;
      }

      h3 {
        color: $c-grey-lightest;
      }

      .panel-group {
        .panel-default {
          border: none;
          box-shadow: none;
          background-color: #FBFBFB;

          .panel-heading {
            padding: 0;
            background-color: #FBFBFB;

            .panel-title {
              .promotion-collapse {
                display: block;
                padding: 5px 0;
                color: #707070;
              }

              .promotion-collapse:focus {
                outline: none;
              }

              .promotion-collapse i {
                padding-top: 6px;
              }

              .fa-sort-up,
              .fa-sort-down {
                position: relative;
                float: right;
                padding-right: 5px;
                color: $c-primary;
              }

              > a.collapsed {
                .fa-sort-down {
                  display: block;
                }

                .fa-sort-up {
                  display: none;
                }
              }

              > a {
                .fa-sort-up {
                  display: block;
                  margin-top: 5px;
                }

                .fa-sort-down {
                  display: none;
                }
              }
            }
          }

          .panel-body {
            border: none;
            background-color: #FBFBFB;
          }

          .promotion-3 {
            padding-bottom: 0;
          }
        }
      }
    }

    .desktop {
      h4 {
        margin: 0;
      }

      img {
        width: 100%;
      }
    }
  }

  #pleins h3, #new h3, #selecteds h3, #adjustable-products h3 {
    margin-bottom: 0;
  }

  #new {
    padding-top: 16px;
  }
}


.promotion-page-new-style {
  h1{
    color: $brand-purple;
    letter-spacing: -0.025em;
    font-weight: 900;
  }

  h2, h3 {
    color: $brand-purple;
    letter-spacing: -0.025em;
    font-size: em($f-size-l);
    font-weight: 900;
  }
  .sustainable__certification {
    &-image{
      min-width: 150px;
      max-width: 150px;
      @include media-breakpoint-down(md){
        min-width: 100px;
        max-width: 100px;
      }
    }
    &-block {
      &:nth-of-type(even) {
        background-color: #DCF5F9;
      }
    }
  }
  .homepage-banner {
    text-align: center;
    margin-bottom: 25px;
  }

  a.btn-promo {
    background: $c-yellow;
    color: $brand-purple;
    font-size: em($f-size-m);
    font-weight: 900;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 16px;
    margin: 18px auto 0 auto;
    @include media-breakpoint-down(sm){
      font-size: 1.25em;
      line-height: 1;
    }
    &:hover{
      background-color: lighten($c-yellow, 10%);
    }
  }
  .btn-valentine-outline{
    color: $brand-purple;
    border-color: $brand-purple;
    &:hover{
      background-color: $brand-purple;
      color: white;
    }
  }

  #texts {
    .text-container{
      @include media-breakpoint-down(md){
        flex-direction: column-reverse;
      }
      display: flex;
      .image-container{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        @include media-breakpoint-down(md){
          padding: 2rem 0;
        }
      }
    }
    color: $brand-purple;

    p {
      padding-left: 3px;
    }
  }

  #brands {
    .leaf-usp {
      display: block;
      position: absolute;
      top: -10px;
      right: 0;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      line-height: 38px;
      font-weight: bold;
      text-align: center;
      color: white;
      z-index: 1;
    }
    .giftpage__characters-image{
      max-width: unset!important;
    }

    @include media-breakpoint-down(md) {
      .leaf-usp {
        top: 0;
        right: 20px;
      }
    }
  }

  .button-container {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    a {
      padding: 1em;
      font-weight: 600;
      font-size: 1em;
    }
  }

  .giftpage {
    &__banners {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      @include media-breakpoint-up(md){
        margin: 0 5rem;
        gap: 1rem;
      }
      @include media-breakpoint-down(md){
        gap: 0.5rem;
      }

      .homepage-banner {
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .hompage-img {
        display: flex;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 250px;
          object-fit: contain;
          height: auto;
        }
      }
    }

    &__price {
      &-filter-container {
        display: inline-flex;
        max-width: 100%;
        min-width: 100%;
        @include media-breakpoint-up(lg) {
          justify-content: center;
        }
        padding-bottom: 1em;
        overflow-x: scroll;
        gap: 1em;

        a {
          padding: 1em;
          font-weight: 600;
          font-size: 1em;
        }
      }
    }

    &__characters {
      &-slide {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        > span {
          font-weight: 600;
          color: $brand-purple;
        }
      }

      &-image {
        height: 8rem;
        width: 8rem;
        object-fit: contain;
      }
    }
  }

  &-beauty{
    @extend .promotion-page-new-style;
    .giftpage__banners{
      margin: 0;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 1rem;
      .btn-promo{
        background-color: transparent;
      }
      @include media-breakpoint-down(md){
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }
  &-garden{
    @extend .promotion-page-new-style;
    #categories {
      color: red;
      .img-container>div {
        color: $brand-purple;
        background-color: $brand-yellow;
        margin: 0 25%;
        font-weight: 900;
        font-size: 1.333333rem;
        padding: 8px 16px;
        border-radius: 9999px;
        transform: translateY(-25px);
      }
      .owl-item>div>div {
        display: none;
      }
    }
  }
}
