.homepage-banners {
  h4 {
    font-weight: 100;
  }
}

.homepage-merken-shops {
  margin: 0 0 26px 0;

  .brand-image {
    min-height: 45px;
    padding: .125em;
    margin: .125em;

    a img {
      @include transition();

      &:hover {
        opacity: .5;
      }
    }
  }
}
