/*
  Styles that are "add-to-cart-modal" specific
  and styles that override the default bootstrap modal styles.
  Note: this modal uses its own backdrop and js wise uses its own hide and show logic.
*/

.add-to-cart-modal {
  --display: flex;
  &--warning {
    --display: block;
  }

  /*
    To override the inline display: block; / display: none; style that the bootstrap modal addes,
    I needed to use !important to override that propary :(.
    For this modal all the hide and show logic is hijacked by custom js.
  */
  display: var(--display) !important;
  align-items: center;

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: $c-black;
    opacity: 0.5;
  }

  .modal-dialog {
    max-width: 44rem;

    @include media-breakpoint-down(sm) {
      margin: 0;
      overflow-y: auto;
      min-height: 100dvh;
      max-width: 20rem;
    }
  }

  .modal-content {
    min-height: 100dvh;

    @include media-breakpoint-up(md) {
      border-radius: 0.625rem;
      min-height: auto;
    }
  }

  .modal-header {
    &__inner {
      border: none;
      background: none;
      padding: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: inherit;

      &:focus {
        outline: none;
      }

      * {
        pointer-events: none;
      }
    }

    .modal-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-grow: 1;
      font-size: 1.25rem;

      @include media-breakpoint-up(md) {
        font-size: em($f-size-m);
      }

      &__prefix {
        color: $c-primary;
      }
    }

    .modal-close {
      font-size: 1.875rem;
      font-weight: 700;
      line-height: 1;
      text-shadow: 0 1px 0 $c-white;
      padding: 0;
      cursor: pointer;
      background: 0 0;
      border: 0;

      &:active {
        outline: none;
      }
    }
  }
  
  .added-to-card {
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    background-color: lighten($btn-success-bg, 50%);
    border-radius: 3px;
    text-align: center;
    color: $brand-success;
  }

  .modal-main {
    display: grid;
    // 150px should be equal to the image width
    grid-template-columns: 150px 1fr;
    gap: 1rem;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      grid-template-columns: 3fr 5fr 4fr;
      gap: 1.875rem;
    }

    &__item {
      position: relative;
      align-self: center;

      @include media-breakpoint-up(sm) {
        align-self: initial;
      }

      &--related {
        grid-column: 2 span;

        @include media-breakpoint-up(md) {
          grid-column: initial;
        }
      }
    }

    &__image {
      width: 150px;
      height: 150px;
      margin-right: 2rem;
    }

    &__title {
      font-size: 1.125rem;
      margin-bottom: 0.25rem;
    }

    &__volume {
      font-size: 1rem;
    }

    &__price {
      font-size: 0.8rem;
      margin-bottom: 0.125rem;

      strong {
        color: $c-promo;
      }
    }

    &__discount-percentage {
      color: $c-promo;
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    &__review-stars,
    &__review-count {
      display: none;

      @include media-breakpoint-up(sm) {
        display: inline;
      }
    }

    &__usp {
      display: none;
      color: $brand-success;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
  }

  .related-products {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;

    &__title {
      display: block;
    }
  }

  .related-product {
    background: none;
    flex: 1;
    color: $c-grey-plein;
    text-align: center;
    border-radius: 4px;
    border: 1px solid $c-grey-lightest;
    padding: 7px;
    display: block;
    line-height: 19px;

    &:hover {
      background-color: $c-grey-lightest;
    }

    * {
      pointer-events: none;
    }

    &__volume {
      display: block;
    }

    &__price {
      display: block;
      font-size: 0.8rem;
      strong {
        color: $c-promo;
      }
      &-variant{
        @extend .related-product__price;
        color: #808080;
        font-size: 1rem;
      }
    }
  }

  .modal-ctas {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    flex-direction: column-reverse;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }
  }
}
