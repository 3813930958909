.active-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.375rem 0.75rem;
  margin-bottom: 0.625rem;

  &__btn {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    padding: 0.125rem 0.875rem;
    border-radius: 0.875rem;
  }

  &__clear-all-filter {
    border: 1px solid $c-primary;

    &:hover {
      text-decoration: underline;
    }
  }

  &__filter {
    background-color: $c-primary-light;
    border: 1px solid $c-primary-light;
    color: $c-black;

    &:hover {
      color: $c-white;
      background-color: $c-link-hover;
    }
  }
}
