// Fonts
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdu.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7nsDI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url(https://fonts.gstatic.com/s/sourcesanspro/v11/toadOcfmlt9b38dHJxOBGCOFnW3Jk0f09zW_Yln67Ac.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Titles
.title-h1-default,
.title-h1-category,
.title-h1-product,
.title-h1-error,
.title-h1-user-favorite,
.title-h1-user-orders-list,
.title-h1-web-page,
.title-h1-product-detail,
.title-h1-promotion,
.title-h1-cart,
.title-h1-dental-register,
.title-h1-review-create-new,
.title-h1-review-overview,
.title-h1-search-categories,
.title-h1-search-global,
.title-h1-theme-special,
.title-h1-brands-index,
.title-h1-review-overview,
.title-h1-brand-detail {
  line-height: 100%;

  &.title-border-bottom {
    padding-bottom: $space-s;
    border-bottom: 1px solid $c-grey-lighter;
  }
}

.title-h1-brands-index {
  margin-top: 10px;
}

.title-h1-category {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    font-size: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}
.title-h2-dental,
.title-h2-default,
.title-h2-checkout-success,
.title-h2-checkout-payment,
.title-h2-checkout-fail,
.title-h2-checkout,
.title-h2-cart,
.title-h2-product-detail,
.title-h2-theme,
.title-h2-promotion,
.title-h2-brands-index,
.title-h2-user-account-settings,
.title-h2-user-order-detail,
.title-h2-user-login,
.title-h2-user-register,
.title-h2-no-page {
  line-height: 100%;
  &.title-border-bottom {
    padding-bottom: $space-s;
    border-bottom: 1px solid $c-grey-lighter;
  }

  &.plein-blue {
    color: $c-primary;
  }
}
.title-h3-default,
.title-h3-search,
.title-h3-theme {
  line-height: 100%;

  &.title-border-bottom {
    padding-bottom: $space-s;
    border-bottom: 1px solid $c-grey-lighter;
  }
}

// Alignment
.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }

// Transformation
.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics
.font-weight-normal { font-weight: normal; }
.font-weight-bold   { font-weight: bold; }
.font-italic        { font-style: italic; }

.lead {
  font-size: em($f-size-m);
}

@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $brand-success);

@include text-emphasis-variant('.text-info', $brand-info);

@include text-emphasis-variant('.text-warning', $brand-warning);

@include text-emphasis-variant('.text-danger', $brand-danger);
