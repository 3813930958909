// Base class
.tooltip {
	position: absolute;
	z-index: 10;
	display: block;
	font-size: em($f-size-base);
	word-wrap: break-word;
	opacity: 0;

	&.in { opacity: $tooltip-opacity; }

	&,
	&.tooltip-top,
	&.bs-tether-element-attached-bottom {
		padding: $tooltip-arrow-width 0;
		margin-top: -$tooltip-margin;

		.tooltip-arrow {
			bottom: 0;
			left: 50%;
			margin-left: -$tooltip-arrow-width;
			border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
			border-top-color: $tooltip-arrow-color;
		}
	}
	&.tooltip-right,
	&.bs-tether-element-attached-left {
		padding: 0 $tooltip-arrow-width;
		margin-left: $tooltip-margin;

		.tooltip-arrow {
			top: 50%;
			left: 0;
			margin-top: -$tooltip-arrow-width;
			border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
			border-right-color: $tooltip-arrow-color;
		}
	}

	&.tooltip.bottom,
	&.tooltip-bottom,
	&.bs-tether-element-attached-top {
		padding: $tooltip-arrow-width 0;
		margin-top: $tooltip-margin;

		.tooltip-arrow {
			top: 0;
			left: 50%;
			margin-left: -$tooltip-arrow-width;
			border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
			border-bottom-color: $tooltip-arrow-color;
		}
	}
	&.tooltip-left,
	&.bs-tether-element-attached-right {
		padding: 0 $tooltip-arrow-width;
		margin-left: -$tooltip-margin;

		.tooltip-arrow {
			top: 50%;
			right: 0;
			margin-top: -$tooltip-arrow-width;
			border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
			border-left-color: $tooltip-arrow-color;
		}
	}
}

// Wrapper for the tooltip content
.tooltip-inner {
	max-width: $tooltip-max-width;
	padding: $tooltip-padding-y $tooltip-padding-x;
	color: $tooltip-color;
	text-align: center;
	background-color: $tooltip-bg;
	border-radius: 4px;
}

// Arrows
.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}