.invalid-input {
    input, select {
        border: 1px solid $brand-danger;
    }
}

.valid-input {
    input:not([type=radio]){
        outline: 2px solid $brand-success;
    }
    input, select {
        box-shadow: 0px 0px 15px -3px $brand-success;
        padding-right: 20px;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='18' viewBox='0 0 448 512'%3E%3Cpath fill='%2382C81EFF' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 99% center;
    }
}

.error-message-box {
    -webkit-border-radius: 0 0 3px 3px;
       -moz-border-radius: 0 0 3px 3px;
            border-radius: 0 0 3px 3px;
    margin: -3px 0 0;
    padding: .375rem .75rem;
    background: $brand-danger;

    &-item {
        list-style-type: none;
        padding: 0;
        margin: 0;
        color: $c-white;
    }
}

ul.error-display-suggestion-email {
    @extend .error-message-box;
    background: #ff8000;
    &:hover {
        li {
            cursor: pointer;
            color: black;
        }
        background: #fff;
    }
}

// Styling for the newsletter email input field, or input fields with the attribute js-verify
.warning {
    border: 1px solid #e70;
}
input[js-verify]:valid {
    outline: 2px solid $brand-success;
    padding-right: 20px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='18' viewBox='0 0 448 512'%3E%3Cpath fill='%2382C81EFF' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 99% center;

    &.warning {
        outline: none;
        padding-right: 0;
        background: transparent url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' viewBox='0 0 20 20' fill='%23e17100'><text x='7' y='16'>!</text></svg>") no-repeat 99% center;
        border: 1px solid #e70;
    }
}

input[js-verify]:user-invalid {
    border: 1px solid $brand-danger;
}

.email-suggestion-warning {
    padding: 0 .75rem;
    color: #e70;
    font-weight: bold;
    font-size: 0.85rem;
    margin: 0;
    &:hover {
        cursor: pointer;
        span {
            text-decoration: underline;
        }
    }
}
//