.trustpilot-comp {
  --fill-color: #00b67a;
  --empty-color: #dcdce6;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &__trusted {
    font-weight: bold;
    font-size: 1.25rem;
  }

  &__stars {
    display: flex;
    align-items: center;
    width: 8rem;

    svg {
      width: 100%;
    }
    .star {
      &__canvas {
        color: var(--empty-color);

        &--fill {
          color: var(--fill-color);
        }

        &--half {
          color: var(--fill-color);
        }
      }
    }
  }

  &__info {
    strong {
      font-weight: bold;
    }
  }

  &__logo {
    width: 7rem;
    // min rem to align the text in the logo better.
    margin-top: -0.375rem;
  }
}
