.newsletter-subscribe {
  .social-media {
    a {
      display: inline-block;
      margin-right: 10px;
    }
    .container {
      padding: 0;
      img.newsletter-family {
        max-width:450px;
        max-height:450px;
      }
    }
  }

  .error {
    background-color: $brand-danger;
    border-radius: 3px;
    padding: 3px;
    text-align: center;
    color: $c-grey-lightest;
    margin-bottom: 7px;
  }

  .g-recaptcha {
    margin-top: 15px;
  }

  form {
    .form-group {
      margin-bottom: 0;
    }
    label {
      font-weight: bold;
    }
  }

  .heart {
    color: $c-primary;
  }

  .enlist-row {
    .enlist {
      margin-top: 23px;
      .btn.btn-nieuwsbrief {
        margin-left: 0;
      }
    }
    .privacy {
      h3 {
        margin-bottom: 0;
        margin-top: 15px;
        line-height: 27px;
      }
      div {
        font-size: 12px;
        line-height: 15px;
        margin-top: 7px;
      }
    }
  }
  
  .mdc-form-field-main-label {
    margin-bottom: 0;
  }

  .mdc-form-field {
    left: -10px;
    position: relative;
    line-height: 0;
    label {
      position: relative;
      top: -14px;
      font-weight: normal;
    }
  }

  input[type=text], input[type=email] {
    border-color: #AAE7F3;
  }

  .mdc-radio {
    .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
      border-color: $c-primary;
    }
    .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
      border-color: $c-primary;
    }
  }

  .mdc-radio::before, .mdc-radio::after, .mdc-radio .mdc-radio__background::before {
    background-color: rgba(0,0,0,0)
  }
}

@include media-breakpoint-down(md) {
  .newsletter-subscribe {
    .col-xs-5, .col-xs-7 {
      width: 100%;
    }

    .col-xs-5 {
      border-top: 1px solid $c-grey-lighter;
    }

    .enlist-row {
      .col-xs-4, .col-xs-8 {
        width: 100%;
      }
      .col-xs-4 {
        float: right;
        top: -8px;
      }
    }

    .social-media {
      .container {

        border-radius: 3px;

        img {
          width: 100%;
          height: auto;
          position: relative;
        }
      }
    }
  }
}
