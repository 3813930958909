.footer-contact {
  background: #E5F7FF;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: unset;
  }
  &__text {
    color: $c-grey-plein;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__app{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    @include media-breakpoint-up(md){
      justify-content: start;
    }
    &-apple{
      padding: 5.5px 0;
      display: inherit;
    }
  }

  &__service {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include media-breakpoint-up(md) {
      grid-template-columns: 11.25rem 1fr;
    }

    svg {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 19px;
    font-weight: bold;
    line-height: 100%;
  }

  &__subtitle {
    b {
      display: block;
    }
  }

  &__icon-link {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.75rem;
    align-items: center;

    i {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: 2rem 1fr;
    }
  }

  &__openinghours {
    display: block;
    font-size: 0.875rem;
  }

  &__form {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 0.25rem;
    max-width: 25rem;
    min-height: 2.5rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      margin-left: initial;
      margin-right: initial;
    }

    input {
      all: unset;
      background-color: $c-white;
      border-radius: 3px;
      padding-left: 0.5rem;
      text-align: left;
    }
  }

  &__logos {
    min-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      margin-left: initial;
      margin-right: initial;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0.75rem;
    }
  }

  &__logo {
    display: inline-block;
    padding: 0.25rem;
    a{
      display: contents;
    }
    img{
      max-height: 50px;
      object-fit: contain;
    }

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  &__usp {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &--green {
      color: #43b02a;
    }
  }

  &__usps {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      align-items: initial;
      margin-top: 0;
    }
  }
}
