.custom-order-overview {
  .custom-orders {
    .custom-row {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }

    .custom-order {
      border: 1px solid $c-grey-lighter;
      border-radius: $border-radius-sm;
      margin-bottom: $space-m;
      min-height: 100%;

      .custom-order-title {
        line-height: 45px;
        border-bottom: 1px solid $c-grey-lighter;

        span {
          margin-left: 15px;
        }
      }

      .custom-order-content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 15px;
      }

      .custom-order-row {
        flex-grow: 1;
        line-height: 19px;
      }

      .image-row {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        .custom-order-img {
          border: 1px solid $c-grey-lighter;
          border-radius: $border-radius-sm;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          width: 25%;
          height: 25%;
          max-width: 80px;
          max-height: 80px;
          position: relative;

          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }

          .plus-items {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 100%;
            color: $brand-purple;
            font-size: 21pt;
            font-weight: bold;
          }
        }
      }

      .custom-btn {
        flex-grow: 1;
        max-width: 50%;
      }
    }
  }
}

.user-address {
  ul {
    li {
      margin-bottom: 0;

      .empty-info {
        opacity: 0.5;
      }
    }
  }
}

.custom-order-return{
  color: $c-grey;
  font-size: 0.825rem;
}
