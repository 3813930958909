.favorite {
  i {
    animation: beat .15s 4 alternate;
  }

  i {
    color: #FDB4D0;
  }
}

@keyframes beat{
  to { transform: scale(1.2); }
}
