.footer-links {
  color: $c-grey-plein;
  padding: 1rem 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  &__grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(sm) {
      gap: 1rem;
    }

    &--default {
      @include media-breakpoint-up(md) {
        grid-template-columns: 4fr 4fr 4fr;
      }
    }

    &--blog {
      @include media-breakpoint-up(sm) {
        grid-template-columns: 6fr 6fr;
      }

      @include media-breakpoint-up(md) {
        grid-template-columns: 6fr 3fr 3fr;
      }

      > div:last-of-type {
        @include media-breakpoint-up(sm) {
          grid-column: 1 / span 2;
        }

        @include media-breakpoint-up(md) {
          grid-column: auto;
        }
      }
    }
  }

  &__title {
    line-height: 100%;
    font-weight: bold;
    font-size: 15px;
  }

  &__link {
    display: block;
    color: inherit;
    &:hover {
      color: inherit;
      opacity: 0.8;
    }
  }

  &__socials {
    display: inline-flex;
    gap: 1rem;
    margin-top: 0.5rem;
    a{
      display: contents;
    }
    @include media-breakpoint-up(md){
      margin-top:0;
    }
  }
}
