/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  &-gift{
    @extend .owl-carousel;
  }
  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
  }

  .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    height: auto;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
  }

  .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
  }

  .owl-nav.disabled,
  .owl-dots.disabled {
    display: none;
  }

  .owl-nav .owl-prev,
  .owl-nav .owl-next,
  .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-loaded {
    display: block;
  }

  &.owl-loading {
    opacity: 0;
    display: block;
  }

  &.owl-hidden {
    opacity: 0;
  }

  &.owl-refresh .owl-item {
    display: none;
  }

  &.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-grab {
    cursor: move;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;
  }

  &.owl-rtl .owl-item {
    float: right;
  }
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel{
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }
  .owl-animated-in {
    z-index: 0;
  }
  .owl-animated-out {
    z-index: 1;
  }
  .fadeOut {
    animation-name: fadeOut;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
  transition: height 500ms ease-in-out;
}
/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel {
  .owl-item {
    .owl-lazy {
      opacity: 0;
      transition: opacity 400ms ease;
    }

    img.owl-lazy {
      transform-style: preserve-3d;
    }
  }
}

/*
 * 	Default theme - Owl Carousel CSS File
 */

$color-base:			#869791 !default;
$color-white:			#FFF !default;
$color-gray:			#D6D6D6 !default;

//nav

$nav-color: 			$color-white !default;
$nav-color-hover:		$color-white !default;
$nav-font-size: 		14px !default;
$nav-rounded: 			3px !default;
$nav-margin: 			5px !default;
$nav-padding: 			4px 7px !default;
$nav-background: 		$color-gray !default;
$nav-background-hover:	$color-base !default;
$nav-disabled-opacity: 	0.5 !default;

//dots

$dot-width:				10px !default;
$dot-height:			10px !default;
$dot-rounded:			30px !default;
$dot-margin: 			5px 7px !default;
$dot-background:		$color-gray !default;
$dot-background-active:	$color-base !default;


.owl-theme {
  // Styling Next and Prev buttons
  .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    [class*='owl-'] {
      color: $nav-color;
      font-size: $nav-font-size;
      margin: $nav-margin;
      padding: $nav-padding;
      background: $nav-background;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        background: $nav-background-hover;
        color:$nav-color-hover;
        text-decoration: none;
      }
    }
    .disabled {
      opacity: $nav-disabled-opacity;
      cursor: default;
    }
  }

  // Styling dots
  .owl-nav.disabled + .owl-dots {
    margin-top: 8px;
  }

  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: $dot-width;
        height: $dot-height;
        margin: $dot-margin;
        background: $dot-background;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px;
      }

      &.active,
      &:hover {
        span {
          background: $dot-background-active;
        }
      }
    }
  }
}

.owl-custom-theme {
  padding: 0 20px;
  position: relative;
  .owl-custom-nav {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -20px;
    line-height: 40px;
    .owl-prev, .owl-next {
      position: absolute;
      font-size: 1.2em;
      color: $c-grey-light;
      cursor: pointer;
      &.disabled {
        color: $c-grey-lightest;
        cursor: default;
      }
    }
    .owl-prev {
      left: 0;
    }
    .owl-next {
      right: 0;
    }
  }
}
