ul.breadcrumb {
  li {
    &:after {
      content: '\f105';
    }
    &:last-child:after {
      content: '';
    }
  }
}
