/**
 * @section : Generic lay-out styles
 * @project : Pleinshoppen
 */

@import "components/text-advice-price";

input,
button,
textarea,
select {
  outline: none;
}

.info-panel {
  background-color: $c-grey-lightest;
  border: 2px solid $c-grey-lighter;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  float: left;
  width: 100%;
  position: relative;
  margin-top: 15px;
  margin-bottom: 5px;

  &-heading {
    position: absolute;
    top: -2px;
    left: -2px;
    -webkit-border-radius: 4px 0 4px 0;
    -moz-border-radius: 4px 0 4px 0;
    border-radius: 4px 0 4px 0;
    height: 25px;
    width: auto;
    background: $c-grey-dark;
    padding: 0 12px;
  }

  &-primary &-heading {
    background: $c-cart-bg;
    color: $c-white;
  }

  &-alert &-heading {
    background: $c-promo;
    color: $c-white;
  }

  &-info &-heading {
    background: $c-primary;
    color: $c-white;
  }

  &-body {
    padding: 35px 20px 20px;
  }
}

.no-border {
  border: none !important;
}

.no-shadow {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/**********************/
/*    MODAL STYLES    */
/**********************/

.plein-cookie-modal {

  .modal-header {
    padding: $space-m;

    .modal-title {
      float: left;
      font-size: em($f-size-m);

      .color-primary {
        color: $c-primary;
      }
    }

    .close {
      float: right;
      font-size: 30px;
      font-weight: 700;
      line-height: 1;
      text-shadow: 0 1px 0 $c-white;
      padding: 0;
      cursor: pointer;
      background: 0 0;
      border: 0;

      &:active {
        outline: none;
      }
    }
  }

  .modal-footer,
  .modal-body {
    padding: $space-m;
  }
}

.block-outline {
  @include block-outline();
}

.block {
  @include block();

  &-outline {
    @include block-outline();
  }

  &-default, // Old Class name
  &-bg-filled {
    @include block-bg-filled();
  }

  &-no-pad {
    @include block-no-pad();
  }

  &-product {
    &-horizontal {
      width: 100%;
      padding: 0;

      .block-product-contain {
        float: left;
        border-bottom: 1px dashed $c-grey-lighter;
        margin-bottom: $space-m;
        padding-bottom: $space-m;
        width: 100%;

        &:last-child {
          border-bottom: 0px dashed $c-grey-lighter;
          margin-bottom: 0;
        }
      }

      .block-product-img {
        width: 30%;
        float: left;
      }

      .block-product-content {
        width: 70%;
        float: left;
      }

      .btn {
        float: right;
      }
    }

    &-vertical {
      width: 100%;
      padding: 0;

      .block-product-contain {
        float: left;
        width: 33.33%;
        border: 0px solid $c-grey-lighter;
      }

      .block-product-img {
        width: 100%;
        float: left;
      }

      .block-product-content {
        width: 100%;
        float: left;
      }
    }


    p {
      margin-bottom: 15px;
    }

    img {
      float: left;
    }
  }

  .btn {
    white-space: normal;
  }

  h2 {
    a {
      color: $c-grey-plein;
      position: relative;
      top: 3px;
    }
  }

  &-default {
    background: $c-grey-lightest;
    height: auto;
    border: 1px solid $c-grey-lighter;
  }

  &-no-pad {
    &-l {
      padding-left: 0;
    }

    &-r {
      padding-right: 0;
    }

    &-t {
      padding-top: 0;
    }
  }

  .btn {
    white-space: normal;
  }

  .list-unstyled {
    min-height: 125px;
    margin-top: -$space-xs;

    li {
      padding-left: $space-l;
      position: relative;
      padding-bottom: $space-xs;
    }

    i {
      font-size: 10px;
      position: absolute;
      left: 0;
      top: 7px;
    }

    span {
      font-size: em($f-size-base);
    }
  }
}

#plein-overlay {
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
  background: transparentize(black, 0.5);
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -998;
  top: 0;
  visibility: hidden;

  &.active {
    visibility: visible;
    z-index: 998;
    opacity: 1;
  }
}

.page-overlay-elements {
  position: fixed;
  z-index: 999999; // this puts it above zopim
  width: 100%;
  bottom: 0;
  height: 0;
}

@include media-breakpoint-up(md) {
  .progress {
    width: 100%;
  }
}

.fas.pull-left,
.far.pull-left,
.fal.pull-left,
.fab.pull-left {
  margin-right: 0.3em;
}

.fas.pull-right,
.far.pull-right,
.fal.pull-right,
.fab.pull-right {
  margin-left: 0.3em;
}
