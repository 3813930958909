.category-results {
  padding-top: $space-xs;
}

.category-content {
  margin-top: $space-m;
  border-top: 1px solid $c-grey-lighter;
  padding-top: $space-m;
  padding-bottom: $space-m;
  margin-bottom: $space-m;
}

.category-banners {
  h4 {
    font-weight: 100;
    margin-bottom: $space-l;
  }
}

.plein-banner-image {
  width: 100%;
}

@include media-breakpoint-down(sm) {
  .category-sorting {
    margin-top: $space-m;
    margin-bottom: $space-m;
  }

  .category-results {
    padding-top: $space-m;
  }
}

@include media-breakpoint-down(xl) {
  .category-content {
    p > img {
      width: 100%;
      height: auto;
      max-width: 1024px;
    }
  }
}

@include media-breakpoint-up(md) {
  .category-results {
    text-align: center;
  }
}
