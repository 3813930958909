.text-advice-price {
    font-size: 12px;
    color: $c-grey;
    margin-left: 3px;
    display: inline-block;
    vertical-align: top;
    padding-top: 3px;
    text-decoration: none;

    &--primary {
        display: block;
        text-align: right;
        color: #999;
    }

    &--category-title {
        color: #999;
    }
}
