.footer {
  background: #E5F7FF;
  width: 100%;
  padding-top: 50px;
  margin-top: 25px;

  &-cat {
    transform: scaleX(-100%);
    @include media-breakpoint-up(md){
      display: block;
      position: absolute;
      right: -5px;
      bottom: 50px;
    }
    display: none;
  }
  &-logo{
    path{
      fill: $c-primary;
    }
  }
  &-pleun{
    @include media-breakpoint-up(lg){
      display: block;
      margin-left: -150px;
      margin-right: 10px;
    }
    display: none;
  }
  &-relative{
    position: relative;
  }
  &-wrapper {
    padding-bottom: 25px;
    @include media-breakpoint-up(md){
      text-align: start;
    }
    text-align: center;
  }
}
