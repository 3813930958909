$sso-buttons-max-width: 375px;

.sso-buttons {
  &__divider{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &-line{
      height: 0;
      width: 100%;
      border-bottom: 1px solid $c-grey-light;
    }
    &-text{
      padding: 0 1rem;
      font-weight: 600;
      min-width: fit-content;
    }
  }
  &__clamp {
    width: 100%;
    height: 40px;
    max-width: $sso-buttons-max-width;
  }

  &__container {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    gap: 0.5rem;
    align-items: center;

    .signin-button {
      align-self: center;
      min-width: 100%;
      height: 38px;
      display: flex;
      justify-content: center;
    }
  }
}
.sso-btn{
  border: 1px solid $c-grey-light;
  border-radius: 5px;
  background-color: white;
  padding: 0;
  transition: all 0.2s ease-in-out;
  &:hover{
    background-color: whitesmoke;
  }
  &-disconnect{
    @extend .sso-btn;
    border: 1px solid $brand-danger;
    .sso-btn-text{
      color: $brand-danger;
    }
    &:hover{
      background-color: $brand-danger;
      .sso-btn-text{
        color: white;
      }
      .sso-btn-icon-wrap{
        background-color: white;
        border-radius: 4px;
      }
    }
  }
  &.apple{
    @extend .sso-btn;
    color: white;
    background-color: black;
    &:hover{
      background-color: #1f1f1f;
    }
  }
  &-contents{
    display: flex;
    padding: 0 3rem;
    align-items: center;
    min-height: 100%;
  }
  &-icon{
    height: 20px;
    min-width: 20px;
    width: 20px;
    &-wrap{
      padding: 4px;
      background-color: transparent;
    }
    &.apple {
      &>svg>path{
        fill: white;
      }
    }
  }
  &-text{
    text-decoration: none;
    color: $c_black;
    font-weight: 600;
    letter-spacing: -0.025em;
    margin: 0 auto;
    &.apple{
      @extend .sso-btn-text;
      color: white;
    }
  }
}
