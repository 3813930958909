$slider-line-height: 20px !default;
$slider-border-radius: 4px !default;
$slider-horizontal-width: 100% !default;
$slider-vertical-height: 100% !default;

@mixin slider_background-image ($colorstart:#f5f5f5, $colorend:#f9f9f9, $backcolor: #f7f7f7) {
  background-color: $backcolor;
  background-image: -moz-linear-gradient(top, $colorstart, $colorend);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($colorstart), to($colorend));
  background-image: -webkit-linear-gradient(top, $colorstart, $colorend);
  background-image: -o-linear-gradient(top, $colorstart, $colorend);
  background-image: linear-gradient(to bottom, $colorstart, $colorend);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$colorstart}', endColorstr='#{$colorend}',GradientType=0);
}
@mixin slider_box-sizing ($value) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}
@mixin slider_box-shadow ($value...) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}
@mixin slider_border-radius ($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

.slider {
  margin-top: 25px;
  display: block;
  vertical-align: middle;
  position: relative;
  &.slider-horizontal {
	width: $slider-horizontal-width;
	height: $slider-line-height;
	.slider-track {
	  height: $slider-line-height/2;
	  width: 100%;
	  margin-top: -$slider-line-height/4;
	  top:  50%;
	  left: 0;
	}
	.slider-selection, .slider-track-low, .slider-track-high {
	  height: 100%;
	  top: 0;
	  bottom: 0;
	}
	.slider-tick,
	.slider-handle {
	  margin-left: -$slider-line-height/2;
	  margin-top: -$slider-line-height/4;
	  &.triangle {
		border-width: 0 $slider-line-height/2 $slider-line-height/2 $slider-line-height/2;
		width: 0;
		height: 0;
		border-bottom-color: #0480be;
		margin-top: 0;
	  }
	}
	.slider-tick-label-container {
	  white-space: nowrap;

	  .slider-tick-label {
		margin-top: $slider-line-height * 1.2;
		display: inline-block;
		text-align: center;
	  }
	}
  }
  &.slider-vertical {
	height: $slider-vertical-height;
	width: $slider-line-height;
	.slider-track {
	  width: $slider-line-height/2;
	  height: 100%;
	  margin-left: -$slider-line-height/4;
	  left: 50%;
	  top: 0;
	}
	.slider-selection {
	  width: 100%;
	  left: 0;
	  top: 0;
	  bottom: 0;
	}
	.slider-track-low, .slider-track-high {
	 width: 100%;
	 left: 0;
	 right: 0;
   }
   .slider-tick,
   .slider-handle {
	margin-left: -$slider-line-height/4;
	margin-top: -$slider-line-height/2;
	&.triangle {
	  border-width: $slider-line-height/2 0 $slider-line-height/2 $slider-line-height/2;
	  width:  1px;
	  height: 1px;
	  border-left-color: #0480be;
	  margin-left: 0;
	}
	.slider-tick-label-container {
	  white-space: nowrap;

	  .slider-tick-label {
		padding-left: $slider-line-height * .2;
	  }
	}
  }
}
&.slider-disabled {
  .slider-handle {
	@include slider_background-image(#dfdfdf, #bebebe, #f7f7f7);
  }
  .slider-track {
	@include slider_background-image(#e5e5e5, #e9e9e9, #f7f7f7);
	cursor: not-allowed;
  }
}
input {
  display: none;
}
.tooltip-inner {
  white-space: nowrap;
  max-width: none;
}
.tooltip {
  &.top {
	margin-top: -36px;
  }
}

.hide {
  display: none;
}
}

.slider-track {
	position: absolute;
	cursor: pointer;
	@include slider_background-image(#f5f5f5, #f9f9f9, #f7f7f7);
	@include slider_box-shadow(inset 0 1px 2px rgba(0,0,0,0.1));
	@include slider_border-radius($slider-border-radius);
}

.slider-selection {
	position: absolute;
	@include slider_background-image(#f9f9f9, #f5f5f5, #f7f7f7);
 @include slider_box-shadow(inset 0 -1px 0 rgba(0,0,0,0.15));
 @include slider_box-sizing(border-box);
 @include slider_border-radius($slider-border-radius);
}
.slider-selection.tick-slider-selection {
  @include slider_background-image(#89cdef, #81bfde, #f7f7f7);
}

.slider-track-low, .slider-track-high {
  position: absolute;
  background: transparent;
  @include slider_box-sizing(border-box);
  border-radius: slider_border-radius($slider-border-radius);
}

.slider-handle {
	position: absolute;
	width:  $slider-line-height;
	height: $slider-line-height;
  background-color: #337ab7;
  @include slider_background-image(#149bdf, #0480be, #0e90d2);
  @include slider_box-shadow(inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05));
  border: 0px solid transparent;
  &.round {
	@include slider_border-radius($slider-line-height);
  }
  &.triangle {
	background: transparent none;
  }
  &.custom {
	background: transparent none;
	&::before{
	  line-height: $slider-line-height;
	  font-size: 20px;
	  content: '\2605'; //unicode star character
	  color: #726204;
	}
  }
}

.slider-tick {
  position: absolute;
  width: $slider-line-height;
  height: $slider-line-height;
  @include slider_background-image(#f9f9f9, #f5f5f5, #f7f7f7);
  @include slider_box-shadow(inset 0 -1px 0 rgba(0,0,0,0.15));
  @include slider_box-sizing(border-box);
  filter: none;
  opacity: 0.8;
  border: 0px solid transparent;
  &.round {
	border-radius: 50%;
  }
  &.triangle {
	background: transparent none;
  }
  &.custom {
	background: transparent none;
	&::before{
	  line-height: $slider-line-height;
	  font-size: 20px;
		  content: '\2605'; //unicode star character
		  color: #726204;
		}
	  }
	  &.in-selection {
		@include slider_background-image(#89cdef, #81bfde, #f7f7f7);
		opacity: 1;
	  }
	}
