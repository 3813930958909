.theme {
  &-special {
    .custom-list {
      li {
        i {
          margin-right: 5px;
          padding: 0 5px;
          color: $c-cart-bg;
        }
      }
    }

    .knive-img-large {
      img {
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }

    .subject {
      padding-top: $space-m;
      font-weight: 700;
      color: $c-grey-darker;
    }

    .special {
      &-image-container {
        height: 310px;
        display: block;

        img {
          @include transition();
        }

        &:hover {
          img {
            opacity: 0.7;
          }
        }
      }

      &-products-row {
        > div {
          padding: $space-s 0;
        }

        a {
          float: left;
        }
      }

      &-products-titles {
        text-align: right;
        color: $c-grey-darker;
      }

      &-title {
        color: $c-grey-darker;
      }

    }

    .youtube-player {
      margin: 0;
    }

    .asterix {
      color: $c-grey-darker;
    }
  }
}

@include media-breakpoint-up(md) {
  .theme {
    &-special {
      .theme {
        &-banner {
          min-height: 399px;

          .btn {
            font-size: 30px;
            margin-top: $space-m;
          }

          > div {
            font-size: 20px;
          }

          h1 {
            font-size: 40px;
            line-height: 70px;
          }
        }
      }

      .special {
        &-products-row {
          > div {
            border-top: 1px solid $c-grey-lighter;
            min-height: 80px;

            &.special {
              &-row-fat {
                min-height: 95px;
              }

              &-no-border {
                border-top: 0px solid $c-grey-lighter;
                min-height: 40px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .theme {
    &-special {
      .special {
        &-products-row {
          > div {
            min-height: 60px;

            &.special {
              &-row-fat {
                min-height: 75px;
              }
            }
          }
        }
      }
    }
  }
}
