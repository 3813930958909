.carousel{
  scroll-snap-type: x mandatory;
  &-container{
    position: relative;
  }
  &-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: $brand-purple;
    border: 1px solid $c-grey;
    padding: 10px;
    box-shadow: 1px 1px 1px $c-grey;
    &:disabled{
      display: none;
    }
    &-prev{
      @extend .carousel-btn;
      left: -10px;
    }
    &-next{
      @extend .carousel-btn;
      right:-10px;
    }
  }
  &-slide{
    min-width: 90%;
    max-width: 90%;
    width: 90%;
    scroll-snap-align: center;
  }
}
