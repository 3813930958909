.dental-list {
  .list-unstyled {
    min-height: 10px;
  }
}

.dental-top {
  padding-top: 0;
  padding-bottom: 9px;
}

.dental-banner {
  img {
    max-width: 100%;
  }
}

.plein-accordion-container {
  h3 {
    i {
      @include transition();
    }

    &.open {
      i {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
  }

  .youtube-player {
    margin-bottom: $space-m;
  }
}

.dental-icon-right {
  &-time {
    padding-top: 0;
    font-size: 14px;
  }

  &.footer-mailto {
    padding-top: $space-xs;
  }
}

@include media-breakpoint-down(md) {
  .dental {
    padding-left: 0;
  }
}
