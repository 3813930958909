.message {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-bottom: 0.75rem;
    font-weight: bold;

    &--success {
        background-color: lighten($brand-success, 49%);

        .far {
            font-size: 1.25rem;
            color: $brand-success;
            margin-right: .25rem;
        }
    }
}
