.alert {
  padding: $space-m;
  border: 1px solid transparent;
  margin-bottom: 20px;
  border-radius: 4px;
  p { margin-bottom: 0; }

  &-success {
    color: #3c763d;
    background-color: #dff0d8;
    margin-bottom: 10px;
  }

  &-notice {
    color: #8a6d3b;
    background-color: #fcf8e3;
  }

  &-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
  }

  &-error {
    color: #a94442;
    background-color: #f2dede;
  }

  &-info {
    color: #004085;
    background-color: #cce5ff;
  }

  .close {
    float: right;
    border: none;
    background: none;
  }

  &-plein {
    text-align: center;
  }
}
