.search-results {
  --search-results-spacing: 1rem;
  display: none;

  .searching & {
    display: block;
  }

  &:before {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($c-black, 0.2);
    z-index: 12;
  }

  &__cart {
    max-height: calc(100vh - var(--search-bar-height));
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $c-white;
    border: 1px solid $c-grey-lighter;
    z-index: 12;
    overflow: auto;

    @include media-breakpoint-up(md) {
      max-height: calc(100vh - 8rem);
      margin: 0 0.938rem;
      border-radius: 0 0 1rem 1rem;
      top: 100%;
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
      max-height: calc(100vh - 5rem);
      top: calc(100% - 0.75rem);
      padding-top: 1rem;
    }
  }
}

.search-result {
  --first-column-width: 5rem;
  color: $c-grey-plein;
  padding: calc(var(--search-results-spacing) / 2) var(--search-results-spacing);

  @mixin grid {
    display: grid;
    grid-template-columns: var(--first-column-width) auto;
    gap: 1rem;
    align-items: center;
  }

  &:is( a ):hover {
    color: inherit;
    background-color: rgba($c-primary, 0.2);
  }

  &__title {
    padding: 0 var(--search-results-spacing);
    text-align: right;
    font-size: 0.75rem;
    color: $c-grey;

    &:not( :first-of-type ) {
      border-top: 1px solid $c-grey-lighter;
    }
  }

  &__search {
    display: block;
  }

  &__match {
    font-weight: normal;
  }

  &__brand {
    @include grid();

    &__image {
      width: 100%;
      max-width: var(--first-column-width);
    }

    &__name {
      display: block;
      font-weight: bold;
      line-height: 100%;
    }

    &__count {
      font-weight: lighter;
    }
  }

  &__no-result {

    &__title {
      display: block;
      font-weight: bold;
    }

    &__value {
      color: inherit;
      display: block;
      margin-bottom: 0.25rem;
    }

    ul {
      all: unset;
      list-style: none;

      li {
        display: flex;
        align-items: center;

        &:before {
          content: "\f00c";
          font-family: 'Font Awesome 5 Pro';
          color: $brand-success;
          font-size: 1.25rem;
          margin-right: 0.75rem;
        }
      }
    }
  }

  &__popular {
    @include grid();

    &__image {
      width: 100%;
      max-width: var(--first-column-width);
    }

    &__name {
      display: block;
    }

    &__stars {
      display: inline-block;
    }

    &__ratings {
      font-size: 0.8rem;
      vertical-align: text-bottom;
    }

    &__price {
      display: block;
      font-weight: bold;
    }
  }
}
