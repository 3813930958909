.cookie {
  width: 100%;
  z-index: 9999;
  background: #F0F0F0;
  color: #51585C;
  bottom: 0;
  position: fixed;
  padding: $space-s;
  margin: 0 auto;
  font-size: $f-size-s;

  .container  {
    span { display: inline-block; }
    a { color: $c-primary; }

    i {
      line-height: 15px;
      cursor: pointer;
      font-size: 14px;
    }
  }
  @include media-breakpoint-down(md) {
    bottom: auto;
    position: static;
    font-size: $f-size-xs;
    .container {
      span { width: 90%; }
      i { line-height: 32px; }
    }
  }
}

.cookie-rule {
  margin-bottom: $space-m;

  .mail-overlay {
    z-index: 10;
    width: 60px;
    height: 25px;
    display: block;
    position: absolute;
  }
}

.cookie-date {
  color: $c-grey-dark;
  font-size: 13px;
}

.mdc-switch {
  margin-left: $space-s;
  margin-top: 8px;
}

.mdc-switch__native-control:enabled:checked ~ .mdc-switch__background:before {
  background-color: $c-primary;
}

.mdc-switch__native-control:enabled:checked ~ .mdc-switch__background .mdc-switch__knob {
  background-color: $c-primary;
}

.mdc-switch__native-control:enabled:checked ~ .mdc-switch__background .mdc-switch__knob:before {
  background-color: $c-primary;
}

.cookie-header {
  font-weight: bold;
  margin-bottom: $space-m;
}

.cookie-declaration{
  border: 1px solid $c-grey;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  &>summary{
    font-weight: bold;
    font-size: 1.2rem;
    position: relative;
    cursor: pointer;
    transition: color 0.2s ease;
    &:hover{
      color: $c-primary;
    }
    &:after{
      transition: transform 0.2s ease;
      position: absolute;
      right:0;
      content: '\f077';
      font-family: 'Font Awesome 5 Pro';
      transform: rotate(0deg);
    }
  }
  &[open]{
    &>summary:after{
      transform: rotate(180deg);
    }
  }
}

@include media-breakpoint-down(xsm) {
  .cookie {
    padding: $space-xs;
    .btn {
      width: 100%;
    }
  }
}

#CybotCookiebotDialogHeader{
  display: none!important;
}
