@include media-breakpoint-down(md) {
  .brand-list {
    ul {
      margin-bottom: 0;
    }
  }

  .brand-list-container {
    margin-bottom: $space-m;
  }
}