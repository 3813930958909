select {
    border: 1px solid $c-grey-lighter;
    display: block;
    width: 100%;
    line-height: 1.5;
    -webkit-appearance: none;
    background: $c-white calc(100% - 3px) url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAASCAYAAABM8m7ZAAAAz0lEQVR42r3ToRKCQBDGcSQZ7gloJqvRxgPoM9il2Y1UXoN0iWS1XiOZKRQS5QoBP2e2XdjbY8ed+df9pS/D1ahFFnXoRb0pR/XoQw3UiCY0I0911/sj4/rdBa1KLegYBZNuleAmQBi4QPNGdEJGBBNebYQr+iWGc+QS0R7lSTDhJ7QkwKUADWHCGyFqGSQaNmiIRD06qMBIsu2aAURw7LZHZPRhfts3FhDD/LYd2unD/LbPcoyHuW23UkQOh9v2qPgDHGz7KQV4mMdLtNeCv64ILZFuwH6iAAAAAElFTkSuQmCC") no-repeat;
    background-size: 10px 6px;
    text-align: center;
    padding: 7px 16px;
}

.custom-order-table {
    tr {
        cursor: pointer;
    }
}
