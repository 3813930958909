.quantity-label {
  --width: 2rem;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @include media-breakpoint-up(sm) {
    top: 0;
    bottom: auto;
  }

  &:before,
  &:after {
    background-color: $c-primary;
    border-radius: 0.188rem;
    color: $c-white;
    font-weight: 700;
    width: var(--width);
    letter-spacing: 0;
    text-align: center;
  }

  &:before {
    display: none;
    content: "Multipack";
    writing-mode: vertical-rl;
    transform: scale(-1);
    text-transform: uppercase;
    padding: 0.5rem 0.2rem 0.5rem 0;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  &:after {
    content: attr(data-quantity);
    padding: 0.25rem 0;
  }

  /*
    styling for when this component is used inside the product-preview component.
    the var(--product-preview-spacing) is only defined when used in this component
  */
  &--product-preview {
    right: var(--product-preview-spacing);

    @include media-breakpoint-up(sm) {
      top: auto;
      bottom: 0;
    }
  }
  &-variant{
    position: absolute;
    bottom: 0;
    right: 0.25rem;
    display: flex;
    gap: 0.25rem;
    &:before,
    &:after {
      background-color: $c-primary;
      border-radius: 0.188rem;
      color: $c-white;
      font-weight: 700;
      width: var(--width);
      letter-spacing: 0;
      text-align: center;
    }
    &:before {
      content: attr(data-quantity);
      padding: 0.2rem;
      margin-left: 0.2rem;
    }

    &:after {

      display: none;
      content: "Multipack";
      text-transform: uppercase;
      padding: 0.2rem 0.5rem;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
}
