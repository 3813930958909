/**
 * @section : Extra mixins
 * @project : Pleinshoppen
 */

@mixin _assert-ascending($map, $map-name) {
	$prev-key: null;
	$prev-num: null;
	@each $key, $num in $map {
		@if $prev-num != null and $prev-num >= $num {
			@warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
		}
		$prev-key: $key;
		$prev-num: $num;
	}
}

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 544px, md: 768px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 544px, md: 768px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 544px, md: 768px))
//    544px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.1.
//
//    >> breakpoint-max(sm, (xs: 0, sm: 544px, md: 768px))
//    767px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	@include media-breakpoint-up($name, $breakpoints) {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	@include media-breakpoint-up($lower, $breakpoints) {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

// Form validation states
//
// Used in _forms.scss to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation($color) {
  // Color the label and help text
  .text-help,
  .form-control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label  {
    color: $color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $color;
    // @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work

    &:focus {
      // border-color: darken($border-color, 10%);
      // $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($border-color, 20%);
      // @include box-shadow($shadow);
    }
  }

  // Set validation states also for addons
  .input-group-addon {
    color: $color;
    border-color: $color;
    background-color: lighten($color, 40%);
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $color;
  }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-border-focus` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus() {
  &:focus {
    border-color: $input-border-focus;
    outline: none;
    $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $input-box-shadow-focus;
    box-shadow: $shadow;
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

@mixin input-size($parent, $input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  #{$parent} {
    height: $input-height;
    padding: $padding-vertical $padding-horizontal;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
  }

  select#{$parent} {
    height: $input-height;
    line-height: $input-height;
  }

  textarea#{$parent},
  select[multiple]#{$parent} {
    height: auto;
  }
}

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  $active-background: darken($background, 10%);
  $active-border: darken($border, 12%);
  color: $color;
  background-color: $background;
  border-color: $border;

  @include hover {
    color: $color;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    color: $color;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: $active-background;
    border-color: $active-border;
    background-image: none;

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
    @include hover {
      background-color: $background;
      border-color: $border;
    }
  }
}

@mixin button-outline-variant($color) {
  color: $color;
  background-image: none;
  background-color: transparent;
  border-color: $color;

  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: #fff;
    background-color: $color;
    border-color: $color;
  }
  @include hover {
    color: #fff;
    background-color: $color;
    border-color: $color;
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      border-color: lighten($color, 20%);
    }
    @include hover {
      border-color: lighten($color, 20%);
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $f-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: em($f-size);
  line-height: $line-height;
  border-radius: $border-radius;
}

// WebKit-style focus
@mixin tab-focus() {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@mixin hover {
	&:hover { @content }
}

@mixin hover-focus {
	&:focus,
	&:hover {
		@content
	}
}

@mixin plain-hover-focus {
	&,
	&:focus,
	&:hover {
	  @content
	}
}

@mixin hover-focus-active {
	&:focus,
	&:active,
	&:hover {
		@content
	}
}

// Tables
@mixin table-row-variant($state, $background) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table-#{$state} {
    &,
    > th,
    > td {
      background-color: $background;
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover {
    $hover-background: darken($background, 5%);

    .table-#{$state} {
      @include hover {
        background-color: $hover-background;

        > td,
        > th {
          background-color: $hover-background;
        }
      }
    }
  }
}

@mixin opacity($opacity) {
    opacity: $opacity;
    // IE8 filter
    $opacity-ie: ($opacity * 100);
    filter: alpha(opacity=$opacity-ie);
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-top-radius($radius) {
    -webkit-border-top-radius: $radius;
    -moz-border-top-radius: $radius;
    -ms-border-top-radius: $radius;
    border-top-radius: $radius;
}

@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-radius: $radius;
    -moz-border-bottom-radius: $radius;
    -ms-border-bottom-radius: $radius;
    border-bottom-radius: $radius;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
    box-shadow: $shadow;
}

// Panels

@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
    border-color: $border;

    & > .panel-heading {
        color: $heading-text-color;
        background-color: $heading-bg-color;
        border-color: $heading-border;

        + .panel-collapse > .panel-body {
            border-top-color: $border;
        }
        .badge {
            color: $heading-bg-color;
            background-color: $heading-text-color;
        }
    }
    & > .panel-footer {
        + .panel-collapse > .panel-body {
            border-bottom-color: $border;
        }
    }
}

// Typography

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%) !important;
    }
  }
}

@mixin badge-variant($color) {
  background-color: $color;

  &[href] {
    @include hover-focus {
      background-color: darken($color, 10%);
    }
  }
}

// Products
@mixin product-name {
  height: 80px;
}
@mixin product-ratings {
  color: $c-cart-bg;
  height: 18px;
}
@mixin product-discount {
  height: 18px;

  p {
    margin-bottom: 0;
  }
}

//Blocks
@mixin block {
  position: relative;
  display: inline-block;
  padding: $space-l;
  color: $c-grey-plein;
  width: 100%;
  border: 1px solid transparent;
}

@mixin block-outline {
  border: 1px solid $c-grey-lighter;
}

@mixin block-bg-filled {
  background: $c-grey-lightest;
  border: 1px solid $c-grey-lighter;
}

@mixin block-no-pad {
  &-l {
    padding-left: 0;
  }

  &-r {
    padding-right: 0;
  }

  &-t {
    padding-top: 0;
  }
}

@function em($pixels, $context: $f-size-base) {
  @return #{$pixels/$context}rem;
}

