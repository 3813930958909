.custom-content {
  img { display: inline-block; }

  .customer-info {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }

    &-left {
      float: left;
      width: 40%;
    }

    &-right {
      float: left;
      width: 60%;

      .fade-txt {
        color: $c-grey-lighter;
      }
    }
  }

  .category-content {
    float: left;
    width: 100%;
  }

  .group-title {
    position: relative;
    padding-right: 30px;

    .edit {
      position: absolute;
      right: $space-l;
      top: 0;
      font-size: 20px;
    }
  }

  .custom-menu {
    li {
      a {
        &:hover {
          color: $c-primary;
        }
      }
    }
  }
}
