//
// Basic Bootstrap table
//

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: $space-m;

    td,
    th {
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: $table-border-width solid $table-border-color;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }

    .table {
        background-color: $c-white;
    }
}

//
// Condensed table w/ half padding
//

.table-sm {
    td,
    th {
        padding: $table-sm-cell-padding;
    }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
    border: $table-border-width solid $table-border-color;

    td,
    th {
        border: $table-border-width solid $table-border-color;
    }

    thead {
        td,
        th {
            border-bottom-width: (2 * $table-border-width);
        }
    }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
    tbody tr:nth-of-type(odd) {
        background-color: $table-bg-accent;
    }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
    tbody tr {
        @include hover {
            background-color: $table-bg-hover;
        }
    }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
@include table-row-variant(active, $table-bg-active);
@include table-row-variant(success, $state-success-bg);
@include table-row-variant(info, $state-info-bg);
@include table-row-variant(warning, $state-warning-bg);
@include table-row-variant(danger, $state-danger-bg);

// Inverse styles
//
// Same table markup, but inverted color scheme—dark background and light text.

.thead-inverse {
    th {
        color: #fff;
        background-color: $c-grey-darker;
    }
}

.thead-default {
    th {
        color: $c-grey-dark;
        background-color: $c-grey-lightest;
    }
}

.table-inverse {
    color: $c-grey-lightest;
    background-color: $c-grey-darker;

    td,
    th,
    thead th {
        border-color: $c-grey-dark;
    }

    &.table-bordered {
        border: 0;
    }
}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
    display: block;
    width: 100%;
    min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
    overflow-x: auto;

    // TODO: find out if we need this still.
    //
    // border: $table-border-width solid $table-border-color;
    // -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
}

.table-reflow {
    thead {
        float: left;
    }

    tbody {
        display: block;
        white-space: nowrap;
    }

    td,
    th {
        border-top: $table-border-width solid $table-border-color;
        border-left: $table-border-width solid $table-border-color;

        &:last-child {
            border-right: $table-border-width solid $table-border-color;
        }
    }

    tbody,
    tfoot,
    thead {
        &:last-child {
            tr:last-child td,
            tr:last-child th {
                border-bottom: $table-border-width solid $table-border-color;
            }
        }
    }

    // scss-lint:disable ImportantRule
    tr {
        float: left;

        td,
        th {
            display: block !important;
            border: $table-border-width solid $table-border-color;
        }
    }
    // scss-lint:enable ImportantRule
}

.table-custom-td {
    tr {
        td {
            color: $c-grey-darker;

            &:first-child {
                text-align: right;
                width: 25%;
            }

            &:nth-child(2) {
                padding-left: $space-m;
            }
        }
    }
}
