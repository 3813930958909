.theme {
  &-filters {
    button {
      &:hover {
        color: $btn-success-bg;
        background-image: none;
        background-color: transparent;
        @include transition();
      }

      border-color: $btn-success-bg;
      }
    }

    img {
    a {
      color: $c-grey-plein;
      float: left;
      width: 100%;
      height: 100%;
      white-space: inherit;
    }

    .theme-filter {
      &:hover {
        a {
          img {
            @include transform(scale(1.1));
          }

          &.btn {
            color: $btn-success-bg;
            background-image: none;
            background-color: transparent;
            border-color: $btn-success-bg;
          }
        }
      }
    }
  }

  &-banner {
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
      min-width: 100%;
      height: auto;
      width: auto;
    }
  }

  &-content {
    .intro {
      background: $c-grey-lightest;
      border: 1px solid $c-grey-lightest;
      padding: $space-m;
      min-height: 270px;
    }

    .blogs {
      .img-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 235px;
        margin-bottom: $space-m;
      }

      img {
        @include transition(all 0.5s ease-in-out);
        max-width: 100%;

        &:hover {
          @include transform(scale(1.1))
        }
      }
    }

    .brand {
      &:hover {
        .brand-img-container {
          img {
            @include transform(scale(1.1));
          }
        }
      }
    }

    .info-blocks {
      .img-container {
        overflow: hidden;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          display: inline-block;
        }

        > div {
          background: transparent;
          background-repeat: no-repeat;
          background-position: left;
        }

        &-link {
          > div {
            @include transition();
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &:hover {
              transform: scale(1.1);
            }

            img {
              opacity: 0;
            }
          }
        }
      }
    }

    .special-blog-txt {
      min-height: 170px;
    }

    .btn-pleinfestijn {
      position: absolute;
      right: 1%;
      top: 30%;
    }

    .Verwenjezelfverraseenander {
      .brand-img-container {
        max-height: inherit;
      }

      .brand-content {
        height: 70px;
        min-height: 70px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .theme {
    &-content {
      .blogs {
        .row {
          min-height: 420px;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .theme {
    &-content {
      .btn-pleinfestijn {
        height: 40px;
        padding-top: 9px;
        right: 3%;
        top: 40%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .theme {
    &-content {
      .btn-pleinfestijn {
        height: 40px;
        width: 210px;
        padding-top: 9px;
        top: 44%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .theme {
    &-content {
      .brand {
        &-content {
          height: 80px;
          font-weight: bold;
        }
      }

      .btn-pleinfestijn {
        height: 51px;
        width: 280px;
        padding-top: 9px;
        right: 3%;
        top: 44%;
        font-size: em(20px);
      }
    }
  }
}
