.review-stars {
  // Full star icon, only works on the :before.
  --full-before: "\f005";
  // Half star icon, only works on the :before.
  --half-before: "\f5c0";
  // Empty star icon, only works on the :after.
  --empty-after: "\f005";

  --before-stars: "";
  --after-stars: var(--empty-after) var(--empty-after) var(--empty-after) var(--empty-after) var(--empty-after);

  display: inline-block;
  font-size: 1rem;
  line-height: 1;

  &:before,
  &:after {
    font-family: "Font Awesome 5 Pro";
    background: $c-yellow;
    letter-spacing: 0.125rem;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:before {
    font-weight: 900;
    content: var(--before-stars);
  }

  &:after {
    font-weight: 400;
    content: var(--after-stars);
  }

  &--1 {
    --before-stars: var(--full-before);
    --after-stars: var(--empty-after) var(--empty-after) var(--empty-after) var(--empty-after);
  }

  &--1-5 {
    --before-stars: var(--full-before) var(--half-before);
    --after-stars: var(--empty-after) var(--empty-after) var(--empty-after);
  }

  &--2 {
    --before-stars: var(--full-before) var(--full-before);
    --after-stars: var(--empty-after) var(--empty-after) var(--empty-after);
  }

  &--2-5 {
    --before-stars: var(--full-before) var(--full-before) var(--half-before);
    --after-stars: var(--empty-after) var(--empty-after);
  }

  &--3 {
    --before-stars: var(--full-before) var(--full-before) var(--full-before);
    --after-stars: var(--empty-after) var(--empty-after);
  }

  &--3-5 {
    --before-stars: var(--full-before) var(--full-before) var(--full-before) var(--half-before);
    --after-stars: var(--empty-after);
  }

  &--4 {
    --before-stars: var(--full-before) var(--full-before) var(--full-before) var(--full-before);
    --after-stars: var(--empty-after);
  }

  &--4-5 {
    --before-stars: var(--full-before) var(--full-before) var(--full-before) var(--full-before) var(--half-before);
    --after-stars: "";
  }

  &--5 {
    --before-stars: var(--full-before) var(--full-before) var(--full-before) var(--full-before) var(--full-before);
    --after-stars: "";
  }

  &__count {
    color: $c-grey;
    font-size: 0.8rem;
    vertical-align: text-top;

    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
}
