.product-preview-grid {
  --columns: 1fr;
  display: grid;
  grid-template-columns: var(--columns);
  gap: 0rem;
  margin-bottom: 1rem;
  @include media-breakpoint-up(sm) {
    --columns: 1fr 1fr;
    gap: 1rem;
  }

  @include media-breakpoint-up(lg) {
    --columns: repeat(4, 1fr);

    &--favorites {
      --columns: repeat(3, 1fr);
    }
  }
  &-variant{
    @extend .product-preview-grid;
    background-color: #f6f6f6;
    box-shadow: 0 0 50px 40px #f6f6f6;
    gap: 1rem;
    @include media-breakpoint-up(sm){
      --columns: repeat(1, minmax(0,1fr));
    }
    @include media-breakpoint-up(md){
      --columns: repeat(2, minmax(0,1fr));
    }
    @include media-breakpoint-up(lg) {
      --columns: repeat(4, minmax(0,1fr));

      &--favorites {
        --columns: repeat(3, minmax(0,1fr));
      }
    }
  }
}
