

.product-preview {
  --container-gap: 15px;
  --container-gap-min: -15px;
  --product-preview-spacing: 0.625rem;
  --product-preview-border-color: #{$c-grey-lighter};
  --product-preview-background-color: #{$c_white};
  position: relative;
  border-top: 0.063rem solid var(--product-preview-border-color);
  border-bottom: 0.063rem solid var(--product-preview-border-color);
  display: flex;
  background-color: var(--product-preview-background-color);
  margin-left: var(--container-gap-min);
  margin-right: var(--container-gap-min);
  padding-left: var(--container-gap);
  padding-right: var(--container-gap);

  @include media-breakpoint-up(sm) {
    border-radius: 0.625rem;
    border: 0.063rem solid var(--product-preview-border-color);
    margin-left: initial;
    margin-right: initial;
    padding-left: initial;
    padding-right: initial;
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    flex-direction: column;
  }

  &:hover {
    --product-preview-border-color: #{$c-primary};
    --product-preview-background-color: #fafdff;
  }
  &-variant{
    @extend .product-preview;
    --product-preview-border-color: #{$c-grey-light};
     &.with-label{
      @include media-breakpoint-down(md){
        padding-top: 25px;
      }
    }
  }

  &__image-wrapper {
    position: relative;
    align-self: baseline;
    flex-basis: 50%;
    @include media-breakpoint-up(sm){
      min-width: 100%;
    }

    @include media-breakpoint-up(md) {
      align-self: initial;
      flex-basis: initial;
    }
    &-variant{
      @extend .product-preview__image-wrapper;
      flex-basis: initial;
    }
  }

  &__image {
    margin: 0 auto;
    padding: var(--product-preview-spacing) 0;
    max-width: 125px;
    height: auto;
    width: 100%;
  }

  &__subinfo-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    grid-column: span 4 / span 4;
  }

  &__skal-label{
    position: absolute;
    bottom: 0;
    left: 10px;
  }
  &__label {
    --label-border-radius: 0.313rem;
    word-wrap: break-word;
    white-space: normal;
    display: inline-block;
    position: absolute;
    top: var(--product-preview-spacing);
    left: var(--container-gap-min);
    z-index: 1;
    font-size: em($f-size-s);
    font-weight: bold;
    &-text{
      display: inline-block;
      pointer-events: none;
      border-radius: 0 var(--label-border-radius) var(--label-border-radius) 0;
      padding: 0.125rem 0.5rem;
      background-color: $c-promo;
      color: $c-white;
      &-variant{
        @extend .product-preview__label-text;
        @include media-breakpoint-up(md) {
          border-radius: var(--label-border-radius) 0 0 var(--label-border-radius);
        }
      }
    }
    @include media-breakpoint-up(sm) {
      left: 0;
    }

    &-variant{
      @extend .product-preview__label;
      left: auto;
      margin-left: -15px;
      @include media-breakpoint-up(sm){
        margin-left: 0;
      }
      @include media-breakpoint-up(md) {
        border-radius: var(--label-border-radius) 0 0 var(--label-border-radius);
        left: auto;
        right: 0;
      }
    }
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--product-preview-spacing) 0;
    flex-grow: 1;
    font-weight: 300;
    flex-basis: 50%;

    @include media-breakpoint-up(sm) {
      padding: var(--product-preview-spacing);
    }

    @include media-breakpoint-up(md) {
      flex-basis: initial;
    }
    &-variant{
      @extend .product-preview__info-wrapper;
      min-width: 70%;
      margin-left: 1rem;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      @include media-breakpoint-up(md){
        margin-left: 0;
        display: flex;
      }
    }
  }

  &__title {
    &-wrapper{
      grid-column: span 4 / span 4;
    }
    &-variant{
      @extend .product-preview__title;
      font-weight: 600;
      letter-spacing: -0.025em;
    }
    color: $c-black;
    margin-bottom: 0.25rem;
  }

  &__volume {
    display: inline-block;
    align-self: baseline;
    white-space: nowrap;
    background-color: $c-white;
    border-radius: 0.188rem;
    border: 0.063rem solid $c-grey-light;
    padding: 0.063rem 0.313rem;
    margin-bottom: 0.25rem;
    &-variant{
      font-weight: 400;
      font-size: 14px;
      border: 0;
      padding: 0;
      color: $brand-purple;
    }
  }

  &__rating {
    display: block;
    grid-column: span 4 / span 4;
    margin-top: auto;
  }

  &__usp {
    display: block;
    color: $c-cart-bg;
    white-space: nowrap;
    line-height: 25px;
    margin-bottom: 0.25rem;
    grid-row-start: 2;
    grid-column: span 4 / span 4;

    &:before {
      content: attr(data-value);
      font-size: 0.86667rem;
      font-weight: 500;
      text-decoration: line-through;
    }

    &:after {
      margin-left: 0.25rem;
      content: attr(data-label);
      font-size: 0.75rem;
      color: $c-grey-icon;
    }
  }

  &__not-available {
    display: block;
    &-variant{
      @extend .product-preview__not-available;
      margin-bottom: 0.25rem;
      grid-row-start: 2;
      grid-column: span 4 / span 4;
    }
  }

  &__discount-percentage {
    min-height: 1.625rem;
    display: block;
    color: $c-promo;
    margin-bottom: 0.25rem;
    &-variant{
      grid-row-start: 5;
      grid-column: span 2 / span 2;
      grid-column-start: 3;
      display: block;
      color: $c-cart-bg;
      font-weight: 600;
      letter-spacing: -0.025em;
      font-size: 14px;
    }
  }

  &__price-and-cta {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    flex-direction: column;
    gap: .5rem;

    &--favorites {
      flex-direction: column;
      align-items: initial;
    }
  }

  &__prices {
    display: flex;
    flex-direction: column;
    line-height: 120%;
    margin-right: 0.25rem;
    &-wrapper{
      display: flex;
      flex-direction: initial;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0.75rem;
      @include media-breakpoint-up(md){
        flex-direction: column;
        justify-content: initial;
        flex-wrap: initial;
      }
    }
    &-variant{
      @extend .product-preview__prices;
      margin-top: auto;
      grid-column: span 2 / span 2;
      margin-bottom: 0.5rem;
    }

    &__suggested {
      grid-column-start: 1;
      grid-row-start: 4;
      grid-column: span 2 / span 2;
      &:before {
        content: attr(data-value);
        font-size: 0.86667rem;
        font-weight: 500;
      }

      &:after {
        margin-left: 0.25rem;
        content: attr(data-label);
        font-size: 0.75rem;
        color: $c-grey-icon;
      }
    }

    &__price {
      font-size: 1.33333rem;
      grid-column-start: 1;
      grid-row-start: 5;
      grid-column: span 2 / span 2;
    }
  }

  &__favorites-btns {
    grid-column: span 2 / span 2;
    display: flex;
    overflow: hidden;
    max-width: 100%;
    justify-content: end;
    height: 48px;
    gap: var(--product-preview-spacing);
    width: 100%;
    .product-preview__btn-variant{
      ::after{
        font-family: "Source Sans Pro", sans-serif;
        content: " Voeg toe";
      }
      margin-top: unset;
      margin-bottom: unset;
      min-height: 100%;
      height: 48px;
      i{
        padding-left: 0;
      }
    }
    @include media-breakpoint-up(md){
      justify-content: space-between;
      height: 40px;
      .product-preview__btn-variant {
        height: 40px;
        max-width: 100%;
        width: unset;
        flex-grow: 1;
      }
    }
  }

  &__trash {
    z-index: 10;
    display: flex;
    align-items: center;
    & + .product-preview__btn-variant{
      @include media-breakpoint-down(sm){
        ::after{
          content: "";
        }
      }
    }
  }

  &__btn {
    position: relative;
    z-index: 10;
    font-weight: 700;
    font-size: 1rem;
    flex-grow: 1;
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 6px;
    line-height: 2.25;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    @include media-breakpoint-up(md) {
     height: 40px;
    }
    &-variant{
      @extend .product-preview__btn;
      &:before{
        content: unset;
      }
      &:focus,
      &:hover {
        &::before{
          content: unset;
        }
      }
      margin-top: auto;
      grid-column: span 2 / span 2;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      width: 100%;
      margin-bottom: 0.25rem;
      padding: 0 10px;
      font-family: inherit;
      height: 40px;

    }
  }

  &__umbrella {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .btn-addcart{
    &:before{
      font-family: "Font Awesome 5 Pro";
      content: "\f07a";
    }
    &:focus,
    &:hover {
      &::before{
        content: "\f067";
      }
    }
  }
}
